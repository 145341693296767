import React, {useContext, useEffect, useState} from "react";
import {UiBox} from "../ui/UiBox";
import {getApiURL} from "../../microservices/api";
import {ApiSensor} from "../api/sensor/ApiSensor";
import {ApiSensors} from "../api/sensors/ApiSensors";
import {ProfileApi} from "../profile/ProfileApi";
import {SensorFirestore} from "../../microservices/sensors";
import {AuthContext} from "../../context/AuthContext";
import firebase from "firebase";

export function Developers() {
    const [sensors, setSensors] = useState<SensorFirestore[]>([]);
    const user = useContext(AuthContext);
    const db = firebase.firestore();

    useEffect(() => {
        getSensors();
    }, []);

    function getSensors() {
        if (!user) {
            return;
        }
        db.collection('devices').where('users', 'array-contains', user.uid).onSnapshot(snapshot => {
            const sensorList: any[] = [];
            const tagList: Set<string> = new Set();
            snapshot.forEach(async doc => {
                const sensor: SensorFirestore = doc.data() as SensorFirestore;
                sensorList.push(sensor);
            });
            setSensors(sensorList);
        });
    }

    function getRandomSensorId(): string {
        if (sensors.length) {
            return sensors[0].id;
        }
        return '0';
    }

    return <>
        <h2>For developers</h2>
        <UiBox header="Developers guide">
            <div>
                To use out API you need to getnerate yourself an API key, under the user profile.
                We have an API for checking the current state of the sensor(s) and also to mark the event of the
                refill. Besides pulling the data we can also push the data to you, for this you have yo generate
                push API key, which will be included in the payload that you push to your URL when there's a change
                in the state of the sensor along with your user email.
            </div>

            <ProfileApi />

            <div className="developers-heading">Get list of your sensors</div>
            <div><strong>Method:</strong> GET</div>
            <div><strong>URL:</strong> {getApiURL('sensors', 'your-email', 'your-api-key')}</div>
            <ApiSensors />

            <div className="developers-heading">Get list of your specific sensor by id</div>
            <div><strong>Method:</strong> GET</div>
            <div><strong>URL:</strong> {getApiURL('sensor', 'your-email', 'your-api-key', 'sensorId')}</div>
            <ApiSensor sensorId={getRandomSensorId()} />

            <div className="developers-heading">Register a refill</div>
            <div><strong>Method:</strong> GET</div>
            <div><strong>URL:</strong> {getApiURL('refill', 'your-email', 'your-api-key', 'sensorId')}</div>
        </UiBox>
    </>
}