import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Welcome from './components/welcome/Welcome';
import reportWebVitals from './reportWebVitals';
import './App.css';
import {AuthProvider} from "./provider/AuthProvider";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Sensors} from "./components/sensors/Sensors";
import {SensorsMapWrapper} from "./components/sensors/SensorsMapWrapper";
import {Login} from "./components/login/Login";
import {SnackbarProvider} from "notistack";
import {Header} from "./components/header/Header";
import {Profile} from "./components/profile/Profile";
import {Developers} from "./components/developers/Developers";
import {WelcomeRoutes} from "./services/configuration";
import {NewSensor} from "./components/sensors/new-sensor/NewSensor";
import {SensorShow} from "./components/sensors/sensor-show/SensorShow";

ReactDOM.render(<React.StrictMode>
    <div className="App">
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
                vertical: 'bottom', horizontal: 'right',
            }}
        >

            <AuthProvider>
                <BrowserRouter>
                    <Header/>
                    <div className="content">
                        <Routes>
                            <Route path={WelcomeRoutes.LOGIN} element={<Login/>}/>
                            <Route path={WelcomeRoutes.PROFILE} element={<Profile/>}/>
                            <Route path={WelcomeRoutes.ROOT} element={<Welcome/>}/>
                            <Route path={WelcomeRoutes.SENSOR_LIST} element={<Sensors/>}/>
                            <Route path={WelcomeRoutes.SENSOR_MAP} element={<SensorsMapWrapper/>}/>
                            <Route path={WelcomeRoutes.NEW_SENSOR} element={<NewSensor/>}>
                                <Route path=":sensorId/:pin" element={<NewSensor/>}/>
                            </Route>
                            <Route path={WelcomeRoutes.SENSOR_DETAILS} element={<SensorShow/>}>
                                <Route path=":sensorId" element={<SensorShow/>}/>
                            </Route>
                            <Route path={WelcomeRoutes.DEVELOPERS} element={<Developers/>}/>
                        </Routes>
                    </div>
                </BrowserRouter>
            </AuthProvider>
        </SnackbarProvider>
    </div>
</React.StrictMode>, document.getElementById('root'))


reportWebVitals();