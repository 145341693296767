import {useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import firebase from "firebase/app";
import {auth} from "../firebase";
import {Login} from "../components/login/Login";

export const AuthProvider: React.FC = ({children}) => {
    const [user, setUser] = useState<firebase.User | null>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            setUser(firebaseUser);
        });

        return unsubscribe;
    }, []);

    return <AuthContext.Provider value={user}>
        {!user && <Login/>}
        {user && <>{children}</>}
    </AuthContext.Provider>;
};

export const SignInWithSocialMedia = (provider: firebase.auth.AuthProvider) =>
    new Promise<firebase.auth.UserCredential>((resolve, reject) => {
        auth.signInWithPopup(provider)
            .then(result => resolve(result))
            .catch(error => reject(error));
    });