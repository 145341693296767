import {getServiceUrl} from "../services/configuration";
import firebase from "firebase";
import {Sensor,Coordinates} from "../models/types/sensor";

export const getUrl = (url: string) => getServiceUrl(url);

export interface SensorProblems {
    description: string;
    logId: string;
    reportedBy: string;
    type: string;
}

export interface SensorSupplies {
    battery: string;
    liquid: string;
    maxLiquid: number;
}

export interface SensorRefill {
    doses: number;
    refillAt: any;
}

export interface SensorAlertType {
    critical: number;
    empty: number;
}

export interface SensorAlerts {
    doses?: SensorAlertType;
    battery?: SensorAlertType;
}

interface SensorSettings {
    device: string;
    type: string;
}

export interface SensorFirestore {
    CloudRegion: string;
    Firmware: string;
    Gateway: string;
    Hardware: string;
    Registry: string;
    Serial: string;
    accessInfo: string;
    city: string;
    contractType: string;
    district: string;
    id: string;
    pin: string;
    lastReportedTimestamp: firebase.firestore.Timestamp;
    location: string;
    coordinates: Coordinates;
    name: string;
    label: string;
    tags: string[];
    phone: string;
    problems: SensorProblems[];
    reportPhone: string;
    supplies: SensorSupplies;
    totalDoses: string;
    type: string;
    refill: SensorRefill;
    alerts: SensorAlerts;
    reportingFrequency?: number;
    reportingTime?: string;
    statuses: any[];
    logs: any[];
    users: string[];
    settings: SensorSettings;
}

export async function updateSensorData(sensorId: string, sensor: Sensor, user: firebase.User) {
    const token = await user.getIdToken(true);
    const postMessage = {
        sensorId: sensorId,
        maxLiquid: sensor.supplies.maxLiquid,
        liquidType: sensor.supplies.liquid,
        batteryType: sensor.supplies.battery,
        label: sensor.label,
        coordinates:sensor.coordinates,
        tags: sensor.tags,
        dosesCritical: sensor?.alerts?.doses?.critical,
        dosesEmpty: sensor?.alerts?.doses?.empty,
        batteryCritical: sensor?.alerts?.battery?.critical,
        batteryEmpty: sensor?.alerts?.battery?.empty,
        reportingFrequency: sensor.reportingFrequency,
        reportingTime: sensor.reportingTime,
        containerType: sensor.containerType,
        messageCenter: sensor.messageCenter.filter(mc => mc.email),
    };

    const response = await fetch(getUrl(`bo/sensor`), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(postMessage)
    });

    return await response.json();
}

export async function refillSensor(sensorId: string, user: firebase.User): Promise<any> {
    const token = await user.getIdToken(true);
    const result = await fetch(getUrl(`bo/sensor/refill/${sensorId}`), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        method: 'GET'
    });

    if (result.status !== 200) {
        throw Error('device not found');
    }
    return result.json();
}

export async function confirmSensorPIN(sensorId: string, sensorPIN: string): Promise<boolean> {
    const response = await fetch(getUrl(`bo/sensor/confirm-pin/${sensorId}/${sensorPIN}`));
    return await response.json();
}