import React from "react";
import {SensorMessageCenterContact} from "../../../../models/types/sensor";
import {UiTextField} from "../../../ui/UiTextField";
import {Grid} from "@mui/material";
import {UiCheckBox} from "../../../ui/UiCheckBox";

interface Props {
    contact: SensorMessageCenterContact;
    changeData: () => void;
}

export function SensorMessagingCenterMessageContact({ contact, changeData }: Props) {

    function changeContact(value: any, type: string) {
        if (type === 'email') {
            contact.email = value.target.value;
        } else if (type === 'warning') {
            contact.warning = value.target.checked;
        } else if (type === 'empty') {
            contact.empty = value.target.checked;
        }
        changeData();
    }

    return <>
        <Grid item xs={10} md={10} lg={10} padding={1}>
            <div><UiTextField label="email" onChange={value => changeContact(value, 'email')} value={contact.email} /></div>
            <div>{contact.warning}</div>
            <div>{contact.empty}</div>
        </Grid>
        <Grid item xs={1} md={1} lg={1} padding={1}>
            <div><UiCheckBox label="warning" checked={contact.warning} onChange={value => changeContact(value, 'warning')} /></div>
            <div>{contact.warning}</div>
            <div>{contact.empty}</div>
        </Grid>
        <Grid item xs={1} md={1} lg={1} padding={1}>
            <div><UiCheckBox label="empty" checked={contact.empty} onChange={value => changeContact(value, 'empty')}/></div>
            <div>{contact.warning}</div>
            <div>{contact.empty}</div>
        </Grid>
    </>;
}