import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {getApiSensors, getApiURL} from "../../../microservices/api";
import {getUserProfile} from "../../../services/user";
import {UiBox} from "../../ui/UiBox";
import {UserProfile} from "../../../models/types/userProfile";
import {Link} from "react-router-dom";
import {WelcomeRoutes} from "../../../services/configuration";

export function ApiSensors() {
    const [apiSensors, setApiSensors] = useState<any>({});
    const [userProfile, setUserProfile] = useState<UserProfile>({} as UserProfile);
    const user = useContext(AuthContext);

    useEffect(() => {
        getSensors();
    }, [user]);

    async function getSensors() {
        if (!user) {
            return;
        }

        const userProfile = await getUserProfile(user.uid);
        setUserProfile(userProfile);
        const apiResponse = await getApiSensors(userProfile.email, userProfile.apiKey);
        setApiSensors(apiResponse);
    }

    if (!userProfile.apiKey) {
        return <p>Generate API key, before we can show the output! <Link to={WelcomeRoutes.DEVELOPERS}>Go to Developers section.</Link></p>;
    }

    return <UiBox header="API output" closeable isClosed>
        <pre>api URL: {getApiURL('sensors', userProfile.email, userProfile.apiKey)}</pre>
        <pre style={{ textAlign: 'left', borderStyle: 'solid', borderColor: 'red', borderWidth: '1px', padding: '5px' }}>{JSON.stringify(apiSensors, null, 4) }</pre>
    </UiBox>;
}