import {Grid} from "@mui/material";
import {UiBox} from "../../../../ui/UiBox";
import {UiTextField} from "../../../../ui/UiTextField";
import React from "react";
import {Sensor, SensorStatus} from "../../../../../models/types/sensor";

interface Props {
    sensor: Sensor;
    changeData: (object: string, value: any) => void;
    rows?: boolean;
    labels?: { consumable: string; warning: string, empty: string };
}

export function SensorShowSettingsConsumable({ sensor, changeData, rows, labels }: Props) {

    return <UiBox header="Levels" margin={0}>
        <Grid container>
            <Grid item xs={rows ? 12 : 4} padding={1}>
                <UiTextField
                    label={labels ? labels.warning : "Warning (%)"}
                    value={sensor?.alerts?.doses?.critical || ''}
                    onChange={(value) => changeData('dosesCritical', value)}
                />
            </Grid>
            <Grid item xs={rows ? 12 : 4} padding={1}>
                <UiTextField
                    label={labels ? labels.empty : "Empty (%)"}
                    value={sensor?.alerts?.doses?.empty || ''}
                    onChange={(value) => changeData('dosesEmpty', value)}
                />
            </Grid>
            <Grid item xs={rows ? 12 : 4} padding={1}>
                <UiTextField
                    label={labels ? labels.consumable : "Maximum liquid level (doses)"}
                    value={sensor?.supplies?.maxLiquid || ''}
                    onChange={(value) => changeData('maxLiquid', value)}
                />
            </Grid>
        </Grid>
    </UiBox>;
}