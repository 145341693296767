import {getUrl} from "./sensors";

export function getApiURL(type: string, userEmail: string, apiKey: string, sensorId?: string): string {
    if (type === 'sensor') {
        return getUrl(`api/sensor/${sensorId}?${new URLSearchParams({ user: userEmail, key: apiKey })}`);
    } else if (type === 'sensors') {
        return getUrl(`api/sensors?${new URLSearchParams({ user: userEmail, key: apiKey })}`);
    } else if (type === 'refill') {
        return getUrl(`api/refill/${sensorId}?${new URLSearchParams({ user: userEmail, key: apiKey })}`);
    }
    return '';
}

export async function getApiSensor(sensorId: string, userEmail: string, apiKey: string): Promise<boolean> {
    const response = await fetch(getApiURL('sensor', userEmail, apiKey, sensorId));
    return await response.json();
}

export async function getApiSensors(userEmail: string, apiKey: string): Promise<boolean> {
    const response = await fetch(getApiURL('sensors', userEmail, apiKey));
    return await response.json();
}