import {Grid} from "@mui/material";
import {UiBox} from "../../../../ui/UiBox";
import {UiTextField} from "../../../../ui/UiTextField";
import React from "react";
import {Sensor} from "../../../../../models/types/sensor";

interface Props {
    sensor: Sensor;
    changeData: (object: string, value: any) => void;
    rows?: boolean;
}

export function SensorShowSettingsBattery({ sensor, changeData, rows }: Props) {
    return <UiBox header="Battery" className="sensor" margin={0}>
        <Grid container>
            <Grid item xs={rows ? 12 : 4} padding={1}>
                <UiTextField
                    label="Critical (volts)"
                    value={sensor?.alerts?.battery?.critical || ''}
                    onChange={(value) => changeData('batteryCritical', value)}
                />
            </Grid>
            <Grid item xs={rows ? 12 : 4} padding={1}>
                <UiTextField
                    label="Empty (volts)"
                    value={sensor?.alerts?.battery?.empty || ''}
                    onChange={(value) => changeData('batteryEmpty', value)}
                />
            </Grid>
            <Grid item xs={rows ? 12 : 4} padding={1}>
                <UiTextField
                    label="Battery model"
                    value={sensor?.supplies?.battery || ''}
                    onChange={(value) => changeData('batteryType', value)}
                />
            </Grid>
        </Grid>
    </UiBox>;
}