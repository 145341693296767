import React from "react";
import {CircularProgress} from "@mui/material";

export function UiSpinner() {

    return <div style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        zIndex: '100',
        backgroundColor: '#ffffff',
        opacity: '0.7',
    }}>
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-25px',
            marginLeft: '-25px',
        }}>
            <CircularProgress />
        </div>
    </div>;
}
