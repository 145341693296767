import React, {useContext, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {UiTextField} from "../ui/UiTextField";
import {UiButton} from "../ui/UiButton";
import {ReactComponent as SaveCheck} from "../../images/check-circle.svg";
import {UserProfile} from "../../models/types/userProfile";
import {UiBox} from "../ui/UiBox";
import {useSnackbar} from "notistack";
import {AuthContext} from "../../context/AuthContext";
import firebase from "firebase";

export function ProfileApi() {
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const {enqueueSnackbar} = useSnackbar();
    const user = useContext(AuthContext);
    const db = firebase.firestore();

    useEffect(() => {
        getUserProfile();
    }, [user]);

    async function getUserProfile() {
        if (!user) {
            return;
        }

        db
            .collection('users')
            .doc(user.uid)
            .onSnapshot(snapshot => {
                const userData: UserProfile = snapshot.data() as UserProfile;
                setUserProfile(userData);
            });
    }


    function generateRandomString(length: number): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function generateKey(type: string) {
        if (!userProfile) {
            return;
        }

        const changedUser: UserProfile = {...userProfile};
        const newKey = generateRandomString(20);

        if (type === 'apiKey') {
            changedUser.apiKey = newKey;
        } else if (type === 'apiPushKey') {
            changedUser.apiPushKey = newKey;
        }

        setUserProfile(changedUser);
    }

    function changeData(object: string, value: any) {
        if (userProfile) {
            const changedUser: UserProfile = {...userProfile};
            if (object === 'apiKey') {
                changedUser.apiKey = value.target.value;
            } else if (object === 'apiPushKey') {
                changedUser.apiPushKey = value.target.value;
            } else if (object === 'apiPushHost') {
                changedUser.apiPushHost = value.target.value;
            }
            setUserProfile(changedUser);
        }
    }

    async function updateProfile() {
        try {
            if (!user || !userProfile) {
                enqueueSnackbar('You have to be logged in to save', {variant: 'error'});
                return;
            }

            await db
                .collection('users')
                .doc(user.uid)
                .update(userProfile);
            enqueueSnackbar('Saved successfully', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Failed to save', {variant: 'error'});
        }
    }

    return <UiBox header="API Configuration">
        <Grid container spacing={2}>
            <Grid item md={4}>
                <UiTextField
                    label="API key"
                    value={userProfile?.apiKey || ''}
                    onChange={(value) => changeData('apiKey', value)}
                />
            </Grid>
            <Grid item md={2}>
                <div style={{paddingTop: '1rem'}}>
                            <span className="hover api-generate" onClick={() => generateKey('apiKey')}>
                                generate</span>
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item md={4}>
                <UiTextField
                    label="API push key"
                    value={userProfile?.apiPushKey || ''}
                    onChange={(value) => changeData('apiPushKey', value)}
                />
            </Grid>
            <Grid item md={2}>
                <div style={{paddingTop: '1rem'}}>
                            <span className="hover api-generate" onClick={() => generateKey('apiPushKey')}>
                                generate</span>
                </div>
            </Grid>
            <Grid item md={6}>
                <UiTextField
                    label="API push-notification address"
                    value={userProfile?.apiPushHost || ''}
                    onChange={(value) => changeData('apiPushHost', value)}
                />
            </Grid>
        </Grid>
        <Grid container margin={1}>
            <Grid item xs={12}>
                <div style={{textAlign: 'right', marginTop: '30px', marginBottom: '10px'}}>
                    <UiButton
                        name="save"
                        icon={<SaveCheck style={{height: '20px', verticalAlign: 'middle'}}/>}
                        onClick={updateProfile}
                    />
                </div>
            </Grid>
        </Grid>
    </UiBox>;

}