import firebase from "firebase";
import {UserProfile} from "../models/types/userProfile";
const db = firebase.firestore();


export async function getUserProfile(userId: string): Promise<UserProfile> {
    return (await db.collection('users').doc(userId).get()).data() as UserProfile;
}

export async function updateUserProfile(userId: string, userProfile: UserProfile): Promise<void> {
    await db.collection('users').doc(userId).update(userProfile);
    return;
}

export async function checkIfUserExists(email: string): Promise<boolean> {
    const userRef = await db.collection('users').where('email', '==', email).get();
    return !!userRef.docs.length;
}

export async function registerUser(email: string, password: string, name: string): Promise<any> {
    const createUserWelcome = firebase.app().functions('europe-west1').httpsCallable('createUserWelcome');
    return createUserWelcome({ name, email, password });
}
