import {UiBox} from "../../../ui/UiBox";
import React from "react";
import {Sensor} from "../../../../models/types/sensor";
import {SensorMessagingCenterMessageContact} from "./MessageContact";
import {Grid} from "@mui/material";

interface Props {
    sensor: Sensor;
    changeData: (object: string, value: any) => void;
}

export function SensorMessagingCenter({ sensor, changeData }: Props) {

    function changeContacts() {
        changeData('messageCenter', sensor.messageCenter);
    }

    if (!sensor.messageCenter) {
        sensor.messageCenter = [];
    }
    sensor.messageCenter = sensor.messageCenter.filter(mc => mc.email);

    sensor.messageCenter.push({ email: '', empty: false, warning: false });

    return <UiBox header="Messaging center">
        <Grid container style={{ marginTop: '10px' }}>
            <Grid container sx={{ display: { lg: 'flex', md: 'none', xs: 'none' } }} style={{ position: 'sticky', top: '0', backgroundColor: '#ffffff' }}>
                <Grid item lg={10} padding={1}><strong>RECEIVER</strong></Grid>
                <Grid item lg={1} padding={1}><strong>WARNING</strong></Grid>
                <Grid item lg={1} padding={1}><strong>{sensor?.containerType === 'recycling-bin' ? 'FULL' : 'EMPTY'}</strong></Grid>
            </Grid>
            {sensor?.messageCenter?.map((mc, index) => <SensorMessagingCenterMessageContact contact={mc} changeData={changeContacts} key={`mc-${index}`} /> )}
        </Grid>
    </UiBox>;
}