import {UiBox} from "../../../ui/UiBox";
import {Grid} from "@mui/material";
import {UiTextFieldTags} from "../../../ui/UiTextFieldTags";
import React from "react";
import {Sensor, sensorTypeMapping, SensorTypes} from "../../../../models/types/sensor";
import {SensorShowSettingsDoses} from "./doses/SensorShowSettingsDoses";
import {SensorShowSettingsCounter} from "./counter/SensorShowSettingCounter";

interface Props {
    sensor: Sensor;
    changeData: (object: string, value: any) => void;
}

export function SensorShowSettings({ sensor, changeData }: Props) {

    return <UiBox header="Other settings">
        <Grid container>
            

            <Grid item xs={12} padding={1}>
                <UiTextFieldTags
                    fullWidth
                    variant="standard"
                    label="Location tags"
                    valueList={sensor.tags || []}
                    changeData={changeData}
                />
            </Grid>
        </Grid>
    </UiBox>;
}