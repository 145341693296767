import {Sensor, SensorBarLevel, SensorStatus, SensorTypes} from "../../../../../models/types/sensor";
import React, {useContext} from "react";
import {
    calculateBatteryPercentage,
    calculateSecondsLeftDistance,
    calculateSensorDistance,
    calculateSensorLiquidLevel,
    TimeToGo
} from "../../../../../services/sensor";
import {Grid} from "@mui/material";
import recycleBin from "../../../../../images/recycle-bin_background.png";
import paperFeeder from "../../../../../images/paper-feeder.png";
import battery from "../../../../../images/battery_background.png";
import verticalBar from "../../../../../images/dispenser.png";
import {UiProgressBar} from "../../../../ui/UiProgressBar";
import {ReactComponent as Paper} from "../../../../../images/paper.svg";
import {UiProgressBarVertical} from "../../../../ui/UiProgressBarVertical";
import {SensorShowSettingsBattery} from "../../settings/settings-battery/SettingsBattery";
import {SensorShowSettingsConsumable} from "../../settings/settings-consumable/SettingsConsumable";
import {SensorShowSettingsGeneral} from "../../settings/settings-general/SettingsGeneral";
import {UiButton} from "../../../../ui/UiButton";
import {ReactComponent as RecyclingPin} from "../../../../../images/recycle-bin.svg";
import {ReactComponent as Droplet} from "../../../../../images/droplet.svg";
import {ReactComponent as Window} from "../../../../../images/window.svg";
import {refillSensor} from "../../../../../microservices/sensors";
import {AuthContext} from "../../../../../context/AuthContext";
import {useSnackbar} from "notistack";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {convertToTimestamp} from "../../../../../services/utils";
import { number } from "yargs";



interface Props {
    sensor: Sensor;
    sensorStatus: SensorStatus;
    sensorStatuses: SensorStatus[];
    changeData: (object: string, value: any) => void;
}

export function SensorShowStatusDistance({ sensor, sensorStatus, sensorStatuses, changeData }: Props) {
    const user = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();

    let barLevel: SensorBarLevel;

    if (sensorStatus.Distance) {
        barLevel = calculateSensorDistance(sensor, sensorStatus);
    } else {
        barLevel = calculateSensorLiquidLevel(sensor, sensorStatus);
    }

    const timeToGo: TimeToGo = calculateSecondsLeftDistance(sensor, sensorStatus);

    // for dragino we're adjusting the max levels
    if (sensor.Type === 'dragino') {
        if (barLevel.percentage > 100 || barLevel.percentage < 0 || (sensor.supplies.maxLiquid - barLevel.current) <= 20) {
            barLevel.outOfRange = true;
        }

        barLevel.percentage = barLevel.percentage < 0 ? 0 : barLevel.percentage;
        barLevel.current = barLevel.current < 0 ? 0 : barLevel.current;
        barLevel.current = barLevel.current > sensor.supplies.maxLiquid ? sensor.supplies.maxLiquid : barLevel.current;
    }

    const progressBarLabel = barLevel.percentage === -1 ? 'Consumable level calculation is not available at the moment' :
        `${barLevel.percentage}% [${barLevel.current}/${barLevel.max}] -
         ${timeToGo.days > 0 ? `${timeToGo.days}d` : ''}
         ${timeToGo.hours > 0 ? `${timeToGo.hours}h` : ''}
         ${timeToGo.days === 0 ? `${timeToGo.minutes}m` : ''}`;

    const labels = {
        warning: 'Warning (%)',
        empty: `${sensor.containerType === 'paper-feeder' ? 'Empty' : 'Full'} (%)`,
        consumable: 'Initial state (mm)',
    };

    async function refill(sensorId: string, user: any) {
        try {
            await refillSensor(sensorId, user);
            enqueueSnackbar('We have set this device as refilled, where the sensor-show is placed.', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Failed to trigger refill', {variant: 'error'});
        }
    }

    let icon;
    switch(sensor.containerType){
        case 'paper-feeder':
            icon = paperFeeder
            break;
        case 'recycling-bin':
            icon = recycleBin
            break;
        case 'dispenser':
            icon = verticalBar
            break;
    }

    let sensorData: { date: string; distance: number | undefined; }[] = [];
    sensorStatuses.forEach((status) => {
        sensorData.push({
            date: convertToTimestamp(status.Timestamp),
            distance: status.Distance
        })
    })
    sensorData = sensorData.reverse();

    return <Grid container wrap="nowrap" >
            <Grid item xs={12} md={3} padding={1}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        height={300}
                        data={sensorData}
                        margin={{
                            top: 15,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                        <XAxis dataKey="date" hide scale="point"/>
                        <YAxis reversed={sensor.containerType === 'recycling-bin' ? true : false} hide domain={[0, Number(sensor?.supplies?.maxLiquid)]}/>
                        <Tooltip />
                        <Line type="monotone" dot={false} dataKey="distance" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
            <Grid item md={2} padding={1}>
                <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: '30vh' }}>
                    <Grid item xs={12} md={12}>
                        {sensor.containerType == "window-sensor" ?
                        <Window className={sensorData[sensorData.length]?.distance ? "windowOpen" : "windowClosed"} ></Window>
                        :
                        sensor.containerType == "other-devices" || sensor.containerType == undefined
                        ?
                        <>
                            <Grid item padding={1}>
                            <UiProgressBar label={progressBarLabel}
                                            percentage={barLevel.percentage}
                                            alerts={sensor?.alerts?.doses}
                                            showAlerts={true}
                                            icon={sensorStatus.Distance ? <Paper style={{ height: '20px' }} /> : <Droplet/>}/>
                            </Grid>
                        </>
                        :
                        <>
                            <UiProgressBarVertical
                            label={progressBarLabel}
                            percentage={barLevel.percentage}
                            alerts={sensor?.alerts?.doses}
                            reverse={sensor.containerType === 'recycling-bin'}
                            outOfRange={barLevel.outOfRange}
                            icon={icon}
                            showAlerts={true}/>
                        </>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={8} padding={1}>
                <Grid container>
                    <Grid item xs={12} md={4} padding={1}>
                        <SensorShowSettingsConsumable sensor={sensor} changeData={changeData} rows labels={labels} />
                    </Grid>
                    <Grid item xs={12} md={4} padding={1}>
                        <SensorShowSettingsGeneral sensor={sensor} changeData={changeData} />
                    </Grid>
                    <Grid item xs={12} md={4} padding={1}>
                        <SensorShowSettingsBattery sensor={sensor} changeData={changeData} rows />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={2} padding={1}>
                <UiProgressBarVertical
                    label={`Battery: ${sensorStatus?.Battery ? sensorStatus.Battery : ''} [${sensorStatus?.CurrentVoltage}]`}
                    percentage={calculateBatteryPercentage(sensor, sensorStatus)}
                    max={5} alerts={sensor?.alerts?.battery} icon={battery}
                    showAlerts={true}/>
            </Grid>
        </Grid>;
}
