import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {ReactComponent as Logout} from '../../images/logout.svg';
import {ReactComponent as Hamburger} from '../../images/hamburger.svg';
import {auth} from "../../firebase";
import {useSnackbar} from "notistack";
import {Link, useLocation} from "react-router-dom";
import {Divider, Grid, Stack} from "@mui/material";
import {AppTheme, AppSkin, WelcomeRoutes} from "../../services/configuration";

export function Header() {
    const user = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, []);

    const location = useLocation();
    const fullPath = location.pathname.split('/');

    const signOut = async () => {
        await auth.signOut();
        enqueueSnackbar('You have been logged out', {variant: 'success'});
    };

    const handleResize = () => {
        if (window.innerWidth < 1100) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    function toggleMenu() {
        setIsMenuOpened(!isMenuOpened);
    }

    function isCurrentPath(path: string): boolean {
        if (path === fullPath[1]) {
            return true;
        } else if (path === 'sensor-show-details/new' && fullPath[1] === 'sensor-show-details' && fullPath[2] === 'new') {
            return true;
        } else if (path === 'sensor-show-log-list' && (fullPath[1] === 'sensor-show-log-list' || (fullPath[1]  === 'sensor-show-details') && fullPath[2] !== 'new')) {
            return true
        }
        return false;
    }

    return (<header className="App-header" style={{ backgroundColor: AppTheme[AppSkin]['backgroundColor'] }}>
        <Grid container>
            <Grid item xs={4} md={2} className="header-logo">
                <img src={AppTheme[AppSkin]['logo']} alt="react logo" />
            </Grid>
            <Grid item xs={2} md={6}>
                <nav className="header-navigation" style={{ textAlign: isMobile ? 'left' : 'center' }}>
                    <div style={{ display: !isMobile ? 'none' : '', cursor: 'pointer', marginBottom: isMenuOpened ? '20px' : '' }} onClick={toggleMenu}>
                        <Hamburger style={{ height: '30px', width: '30px' }} />
                    </div>
                    <Stack
                        style={{ display: isMobile && !isMenuOpened ? 'none' : '' }}
                        direction={!isMobile ? 'row' : 'column'}
                        divider={<Divider orientation="vertical" flexItem style={{borderColor: '#ffffff'}}/>}
                        spacing={2}
                        onClick={toggleMenu}
                    >
                        <Link to={WelcomeRoutes.ROOT} style={{ color: isCurrentPath('') ? '#989898' : '' }}>Welcome</Link>
                        <Link to={WelcomeRoutes.NEW_SENSOR} style={{ color: isCurrentPath('sensor-show-details/new') ? '#989898' : '' }}>Add Sensor</Link>
                        <Link to={WelcomeRoutes.SENSOR_LIST} style={{ color: isCurrentPath('sensor-show-log-list') ? '#989898' : '' }}>My Sensors</Link>
                        <Link to={WelcomeRoutes.SENSOR_MAP} style={{ color: isCurrentPath('sensor-show-map') ? '#989898' : '' }}>Map</Link>
                        <Link to={WelcomeRoutes.PROFILE} style={{ color: isCurrentPath('profile') ? '#989898' : '' }}>My Profile</Link>
                        <Link to={WelcomeRoutes.DEVELOPERS} style={{ color: isCurrentPath('developers') ? '#989898' : '' }}>Developers</Link>
                    </Stack>
                </nav>
            </Grid>
            <Grid item xs={6} md={4} className="header-user">
                <div className="hover" onClick={signOut}>
                    {user?.email}
                    <Logout style={{height: '20px', marginLeft: '20px', verticalAlign: 'middle'}}/>
                </div>
            </Grid>

        </Grid>
    </header>);
}
