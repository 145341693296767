import {SensorAlertType} from "../../microservices/sensors";
import {alertColours} from "../../models/types/sensor";

interface Props {
    percentage: number;
    label: string;
    alerts?: SensorAlertType;
    max?: number;
    icon?: any;
    vertical?: boolean;
    showAlerts? : boolean;
}

export function UiProgressBar({percentage, label, alerts = {critical: 0, empty: 0}, max = 100, icon, vertical, showAlerts}: Props) {

    const alertPercentage = {
        empty: alerts.empty / (max / 100),
        critical: alerts?.critical / (max / 100),
    }

    const barBackgroundColor = alertPercentage.empty > percentage ? alertColours.empty : alertPercentage.critical > percentage ? alertColours.critical : alertColours.normal;
    const barFontColor = alertPercentage.empty > percentage ? '#ffffff' : alertPercentage.critical > percentage ? '#000000' : '#ffffff';

    return (<div style={{
        height: 25, width: '100%', backgroundColor: "#949494", borderRadius: '10px', position: 'relative',
        borderStyle: 'solid', borderWidth: '1px'
    }}>
        <div style={{
            height: '100%',
            position: 'absolute',
            left: '-30px',
        }}>{icon}</div>
        <div style={{
            height: '100%',
            width: `${percentage}%`,
            backgroundColor: barBackgroundColor,
            borderRadius: '10px',
            borderRightStyle: 'solid',
            borderRightWidth: '1px',
            position: 'absolute',
        }}>
        </div>
        <span style={{
            padding: 4, color: barFontColor, fontSize: '0.8rem', position: 'absolute',
        }}>{label}</span>

    {showAlerts ? <>
        {alerts?.empty && <div style={{
            backgroundColor: alertColours.empty,
            height: '40px',
            width: '2px',
            left: `${alertPercentage.empty}%`,
            top: '-8px',
            position: 'absolute',
            zIndex: -1,
        }}
        ><span style={{
            position: 'absolute',
            color: alertColours.empty,
            fontSize: '0.5rem',
            top: '-8px',
            left: '1px',
        }}>{alerts.empty}</span></div>}
        {alerts?.critical && <div style={{
            backgroundColor: alertColours.critical,
            height: '40px',
            width: '2px',
            left: `${alertPercentage.critical}%`,
            top: '-8px',
            position: 'absolute',
            zIndex: -1,
        }}
        ><span style={{
            position: 'absolute',
            color: alertColours.critical,
            fontSize: '0.5rem',
            top: '-8px',
            left: '1px',
        }}>{alerts.critical}</span></div>}
         </> : <></>}
    </div>);
}