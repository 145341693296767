import {TextField} from "@mui/material";
import {useState} from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {useSnackbar} from "notistack";

interface Props {
    variant: 'outlined' | 'standard';
    label: string;
    fullWidth: boolean;
    valueList: string[];
    changeData: (object: string, value: any) => void;
}

export function UiTextFieldTags({changeData, valueList, ...rest}: Props) {
    const [newTag, setNewTag] = useState<string>('');
    const {enqueueSnackbar} = useSnackbar();

    function changeValue(value: string) {
        setNewTag(value);
    }

    function addToTheList(value: React.KeyboardEvent) {
        if (value.key === 'Enter' || value.key === 'NumpadEnter') {
            if (newTag.length < 4 || newTag.length > 12) {
                enqueueSnackbar('Tag may be between 4 and 12 characters', {variant: 'error'});
                return;
            }

            const newTagList = [...valueList];
            if (newTagList.indexOf(newTag) >= 0) {
                return;
            }
            newTagList.push(newTag);
            setNewTag('');
            changeData('tags', newTagList);
        }
    }

    function removeTag(tag: string) {
        const newTagList = [...valueList];
        const index = newTagList.indexOf(tag);
        newTagList.splice(index, 1);
        changeData('tags', newTagList);
    }

    return (<>
        <TextField
            {...rest}
            value={newTag}
            onChange={(value) => changeValue(value.target.value)}
            onKeyDown={(value) => addToTheList(value)}
        />
        <div style={{margin: '20px'}}>
            {valueList.map((tag, index) => <span key={index} style={{ cursor: 'pointer' }} onClick={() => removeTag(tag)}>{tag}
                <HighlightOffIcon style={{verticalAlign: 'text-bottom', height: '18px'}}/><span
                    style={{marginTop: '10px'}}></span>{' '}</span>)}</div>
    </>);
}