import React, {useContext, useEffect, useState, useRef, ReactElement} from "react";
import firebase from "firebase";
import {SensorsTable} from "./table/SensorsTable";
import {AuthContext} from "../../context/AuthContext";
import {UiButton} from "../ui/UiButton";
import {WelcomeRoutes} from "../../services/configuration";
import {Autocomplete, Grid,TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Sensor} from "../../models/types/sensor";
import { SensorsMap } from "./map/SensorsMap";

export function SensorsMapWrapper() {
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [sensorsToShow, setSensorsToShow] = useState<Sensor[]>([]);
  const [search, setSearch] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [selectedFilterTags, setSelectedFilterTags] = useState<string[]>([]);
  const user = useContext(AuthContext);
  const db = firebase.firestore();
  const navigate = useNavigate();

  useEffect(() => {
      getSensors();
  }, []);

  useEffect(() => {
    setSensorsToShow(sensors.filter(sensor => {
        if (selectedFilterTags.length > 0) {
            const foundTag = selectedFilterTags.map(tag => sensor?.tags?.includes(tag));
            if (selectedFilterTags.length !== foundTag.filter(Boolean).length) {
                return false;
            }
        }

        if (search) {
            // Use 'name' if available, otherwise use 'label'
            const displayName = sensor.name || sensor.label;

            const nameMatch = displayName && displayName.toLowerCase().includes(search.toLowerCase());
            const idMatch = sensor.id && sensor.id.toLowerCase().includes(search.toLowerCase());

            if (!(nameMatch || idMatch)) {
                return false;
            }
        }

        return true;
    }));
  }, [sensors, search, selectedFilterTags]);

  function toggleFilterTag(tag: string) {
      const currentSelectedTagList = [...selectedFilterTags];
      const index = currentSelectedTagList.indexOf(tag);
      if (index >= 0) {
          currentSelectedTagList.splice(index, 1);
      } else {
          currentSelectedTagList.push(tag);
      }
      setSelectedFilterTags(currentSelectedTagList);
  }

  function getSensors() {
      if (!user) {
          return;
      }
      db.collection('devices').where('users', 'array-contains', user.uid).onSnapshot(snapshot => {
          const sensorList: any[] = [];
          const tagList: Set<string> = new Set();
          snapshot.forEach(async doc => {
              const sensor: Sensor = doc.data() as Sensor;
              sensor.tags?.forEach((tag: string) => tagList.add(tag));
              sensorList.push(sensor);
          });
          setTags(Array.from(tagList.values()));
          setSensors(sensorList);
      });
  }

  if (!sensors.length) {
    return <div  style={{textAlign: 'center'}}>
        <div>You don't seem to have any sensors</div>
        <div style={{ marginTop: '2rem' }}><UiButton name="add device" onClick={() => navigate(WelcomeRoutes.NEW_SENSOR) } /></div>
    </div>;
  }

  return (<>
  <Grid container xs={12} md={12} lg={12}>
    <Grid item xs={6} sm={4} md={4} lg={12} style={{marginTop: '15px'}}>
      <h2>Sensor map</h2>
    </Grid>
  </Grid>
  <SensorsMap sensors={sensorsToShow} />
  </>);
}