import {Sensor, SensorStatus, sensorTypeMapping, SensorTypes} from "../../../../../models/types/sensor";
import React from "react";
import {Grid} from "@mui/material";
import {convertToTimestamp, getDoorStatus, getOccupancy} from "../../../../../services/utils";

interface Props {
    sensor: Sensor;
    sensorStatuses: SensorStatus[];
}

export function SensorShowStatusLogList({ sensor, sensorStatuses }: Props) {

    if (!sensor.Type || sensorTypeMapping[sensor.Type] === SensorTypes.DOSES) {
        return <>
            <Grid container>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>TIMESTAMP</Grid>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>BATTERY</Grid>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>TOTAL DOSES</Grid>
            </Grid>
            {sensorStatuses?.map((status, index) => {
                return <Grid container key={index}>
                    <Grid item xs={4}>{status?.Timestamp && convertToTimestamp(status.Timestamp)}</Grid>
                    <Grid item xs={4}>{status.Battery} [{status.CurrentVoltage}]</Grid>
                    <Grid item xs={4}>{status.Distance ? status.Distance : status.TotalDoses}</Grid>
                </Grid>
            })}</>;
    }
    if (sensorTypeMapping[sensor.Type] === SensorTypes.DISTANCE) {
        return <>
            <Grid container>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>TIMESTAMP</Grid>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>BATTERY</Grid>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>DISTANCE (mm)</Grid>
            </Grid>
            {sensorStatuses?.map((status, index) => {
                return <Grid container key={index}>
                    <Grid item xs={4}>{status?.Timestamp && convertToTimestamp(status.Timestamp)}</Grid>
                    <Grid item xs={4}>{status.Battery} [{status.CurrentVoltage}]</Grid>
                    <Grid item xs={4}>{status.Distance ? status.Distance : status.TotalDoses}</Grid>
                </Grid>
            })}</>;
    }

    if (sensor.Type === SensorTypes.WINDOW) {
        return <>
            <Grid container>
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>TIMESTAMP</Grid>
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>BATTERY</Grid>
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>Door Status</Grid>
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>Duration(Last Open)</Grid>
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>Open Count</Grid>
            </Grid>
            {sensorStatuses?.map((status, index) => {
                return <Grid container key={index}>
                    <Grid item xs={2}>{status?.Timestamp && convertToTimestamp(status.Timestamp)}</Grid>
                    <Grid item xs={2}>{status.Battery} [{status.CurrentVoltage}]</Grid>
                    <Grid item xs={2}>{getDoorStatus(status.doorStatus)}</Grid>
                    <Grid item xs={2}>{status.doorOpenDuration}</Grid>
                    <Grid item xs={2}>{status.doorOpenTime}</Grid>
                </Grid>
            })}</>;
    }

    if (sensorTypeMapping[sensor.Type] === SensorTypes.MILESIGHT) {
        return <>
            <Grid container>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>TIMESTAMP</Grid>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>BATTERY</Grid>
                <Grid item xs={4} style={{ fontWeight: 'bold' }}>Occupancy</Grid>
            </Grid>
            {sensorStatuses?.map((status, index) => {
                return <Grid container key={index}>
                    <Grid item xs={4}>{status?.Timestamp && convertToTimestamp(status.Timestamp)}</Grid>
                    <Grid item xs={4}>{status.Battery} [{status.CurrentVoltage}]</Grid>
                    <Grid item xs={4}>{getOccupancy(status.Distance)}</Grid>
                </Grid>
            })}</>;
    }

    if (sensor.Type && sensorTypeMapping[sensor.Type] === SensorTypes.COUNTER) {
        if (sensor.Type === 'miromico-fmlr-iot-button') {
            return <>
                <Grid container>
                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>TIMESTAMP</Grid>
                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>BATTERY</Grid>
                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>CONFIG</Grid>
                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>USED CHARGE</Grid>
                </Grid>
                {sensorStatuses?.map((status, index) => {
                    return <Grid container key={index}>
                        <Grid item xs={3}>{status?.Timestamp && convertToTimestamp(status.Timestamp)}</Grid>
                        <Grid item xs={3}>{status.Battery} [{status.CurrentVoltage}]</Grid>
                        <Grid item xs={3}>{status.ButtonConfig}</Grid>
                        <Grid item xs={3}>{status.UsedCharge}</Grid>
                    </Grid>
                })}</>;
        }
        return <>
            <Grid container>
                <Grid item xs={3} style={{ fontWeight: 'bold' }}>TIMESTAMP</Grid>
                <Grid item xs={3} style={{ fontWeight: 'bold' }}>BATTERY</Grid>
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>A</Grid>
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>B</Grid>
                <Grid item xs={2} style={{ fontWeight: 'bold' }}>PAYLOAD COUNTER</Grid>
            </Grid>
            {sensorStatuses?.map((status, index) => {
                return <Grid container key={index}>
                    <Grid item xs={3}>{status?.Timestamp && convertToTimestamp(status.Timestamp)}</Grid>
                    <Grid item xs={3}>{status.Battery} [{status.CurrentVoltage}]</Grid>
                    <Grid item xs={2}>{status.TotalCounterA}</Grid>
                    <Grid item xs={2}>{status.TotalCounterB}</Grid>
                    <Grid item xs={2}>{status.PayloadCounter}</Grid>
                </Grid>
            })}</>;
    }

    return <>
        {sensorStatuses?.map((status, index) => {
            return <Grid container key={index}>
                <Grid item xs={4}>battery: {status.Battery} [{status.CurrentVoltage}]</Grid>
                <Grid item xs={4}>{status?.Timestamp && convertToTimestamp(status.Timestamp)}</Grid>
                <Grid item xs={4}>{status?.Distance ? status.Distance : status.TotalDoses}</Grid>
            </Grid>
        })}</>;
}