import React, {useContext, useEffect, useState, useRef, ReactElement, createRef} from "react";
import {Grid} from "@mui/material";
import {UiBox} from "../../ui/UiBox";
import GoogleMap from 'google-map-react';
import {alertColours, Sensor, SensorBarLevel, SensorStatus} from "../../../models/types/sensor";
import firebase from "firebase";
import { 
  calculateBatteryPercentage,
  calculateSensorDistance,
  calculateSensorLiquidLevel
} from "../../../services/sensor";
import {convertToTimestamp} from "../../../services/utils";
import recycleBin from "../../../images/recycle-bin_background.svg";
import recycleBinEmpty from "../../../images/recycle-bin_background_empty.svg";
import recycleBinNormal from "../../../images/recycle-bin_background_normal.svg";
import recycleBinCritical from "../../../images/recycle-bin_background_critical.svg";
import recycleBinOutOfRange from "../../../images/recycle-bin_background_out-of-range.svg";
import paperFeeder from "../../../images/paper-feeder.svg";
import verticalBar from "../../../images/dispenser.svg";

const apiKey = "AIzaSyB5GDvU3jW7kZA12OfEVQBf04NhOlgJjTg";

interface Props {
    sensors: Sensor[];
}

export function SensorsMap({sensors}: Props) {
  const db = firebase.firestore();
  const mapElementRef = createRef<HTMLDivElement>();

  const sensorsToShow = sensors.filter(sensor => sensor.coordinates && sensor.coordinates.x && sensor.coordinates.y);

  if (sensorsToShow.length === 0) {
    return <>No devices with location information available</>
  }

  // WIP currently just using first sensors location, but really should consider all sensors locations to come up with best center and zoom
  const centerLocation = sensorsToShow[0].coordinates;

  const mapProps = {
    center: {
      lat: Number(centerLocation!.y!) as number,
      lng: Number(centerLocation!.x!) as number
    },
    zoom: 10
  };

  const getBarLevel = (sensor: Sensor, sensorStatus: SensorStatus) => {
    // code duplicated from SensorShowStatusDistance.tsx 45-64
    let barLevel: SensorBarLevel;
    if (sensorStatus.Distance) {
        barLevel = calculateSensorDistance(sensor, sensorStatus);
    } else {
        barLevel = calculateSensorLiquidLevel(sensor, sensorStatus);
    }
    return barLevel;
  }

  const getInfoWindowString = (sensor: Sensor, sensorStatus: SensorStatus) => {
    const barLevel = getBarLevel(sensor, sensorStatus);

    // code duplicated from SensorShowStatusDistance.tsx 45-64
    // for dragino we're adjusting the max levels
    if (sensor.Type === 'dragino') {
      if (barLevel.percentage > 100 || barLevel.percentage < 0 || (sensor.supplies.maxLiquid - barLevel.current) <= 20) {
          barLevel.outOfRange = true;
      }

      barLevel.percentage = barLevel.percentage < 0 ? 0 : barLevel.percentage;
      barLevel.current = barLevel.current < 0 ? 0 : barLevel.current;
      barLevel.current = barLevel.current > sensor.supplies.maxLiquid ? sensor.supplies.maxLiquid : barLevel.current;
    }

    const progressBarLabel = barLevel.percentage === -1 ? 'Consumable level calculation is not available at the moment' :
    `${barLevel.percentage}% [${barLevel.current}/${barLevel.max}] -`;
 
    return `
      <h3>Device type: ${sensor.containerType}</h3>
      <h3>Name:</h3>
      <span>${sensor.label}</span>
      <h3>Sensor ID:</h3>
      <span>${sensor.id}</span>
      <h3>Fill Level:</h3>
      <span>${progressBarLabel}</span>
      <h3>Battery:</h3>
      <span>${sensorStatus && Math.round(calculateBatteryPercentage(sensor, sensorStatus))}%</span>
      <h3>Last Connection:</h3>
      <span>${sensorStatus && sensorStatus.Timestamp && convertToTimestamp(sensorStatus.Timestamp)}</span>
    `;
  };

  const getSensorIconUrl = (sensor: Sensor, sensorStatus: SensorStatus) => {
    const getRecycleBinUrl = (): string => {      
      const barLevel = getBarLevel(sensor, sensorStatus);

      const alerts = sensor.alerts.doses!;
      const percentage = barLevel.percentage;
      const reverse = true;
      const outOfRange = barLevel.outOfRange;

      // copied from UiProgressBarVertical.tsx 17-20
      const alertPercentage = {
        empty: alerts.empty,
        critical: alerts?.critical,
      }

      // copied from UiProgressBarVertical.tsx 22-25
      if (reverse) {
        alertPercentage.empty = 100 - alertPercentage.empty;
        alertPercentage.critical = 100 - alertPercentage.critical;
      }

      // copied and modified from  UIProgressBarVertical.tsx 27
      let recycleBinUrl = recycleBinNormal;

      // copied and modified from  UIProgressBarVertical.tsx 29-33
      if ((reverse && alertPercentage.empty < percentage) || (!reverse && alertPercentage.empty > percentage)) {
          recycleBinUrl = recycleBinEmpty
      } else if ((reverse && alertPercentage.critical < percentage) || (!reverse && alertPercentage.critical > percentage)) {
          recycleBinUrl = recycleBinCritical;
      }

      // copied and modified from UIProgressBarVertical.tsx 60-62
      if (outOfRange) {
          recycleBinUrl = recycleBinOutOfRange;
      }

      return recycleBinUrl;
    }

    let url: string;
    switch(sensor.containerType){
      case 'paper-feeder':
        url = paperFeeder
        break;
      case 'recycling-bin':
        url = getRecycleBinUrl();
        break;
      case 'dispenser':
        url = verticalBar
        break;
      default:
        url = recycleBin
        break;
    }
    return url;
  }

  const handleApiLoaded = (map: any, maps: any) => {
    const createMarker = (sensor: Sensor, sensorStatus: SensorStatus) => {
      // this determines icon size
      const iconSideLength = 32;
      
      const icon = {
        url: getSensorIconUrl(sensor, sensorStatus),
        scaledSize: new maps.Size(iconSideLength, iconSideLength),
        origin: new maps.Point(0, 0),
        anchor: new maps.Point(iconSideLength / 2, iconSideLength / 2)
      };

      const marker = new maps.Marker({
        position: {
          lat: Number(sensor.coordinates!.y!),
          lng: Number(sensor.coordinates!.x!),
        },
        icon: icon,
        map,
      });

      const infoWindow = new maps.InfoWindow({
        content: getInfoWindowString(sensor, sensorStatus)
      });
      // this listener is necessary cause pressing x doesnt automatically update custom field opened
      infoWindow.addListener('closeclick', () => { infoWindow.opened = false; });

      marker.addListener('click', () => {
        // opened is a custom field to track opened/closed state, as google maps doesnt have one
        if (infoWindow.opened) {
          infoWindow.close();
        }
        else {          
          infoWindow.open(map, marker);          
        }
        infoWindow.opened = !infoWindow.opened;
      });
      marker.addListener('mouseover', () => {
        infoWindow.open(map, marker);
      });
      marker.addListener('mouseout', () => {
        if (!infoWindow.opened) {
          infoWindow.close();
        }
      });
    }

    sensorsToShow.forEach((sensor) => {
      db.collection(`devices/${sensor.id}/status`)
        .where('MessageType', '==', 'regular')
        .orderBy('Timestamp', 'desc')
        .limit(1)
        .onSnapshot(snap => {
            snap.docChanges().forEach(change => {
                if (change.type === 'added') {
                  createMarker(sensor, change.doc.data());
                }
            });
        });
    });
  };

  return (<>
  <UiBox header="Sensor map - positions">
    <div id="map-container" style={{height:"600px"}} ref={mapElementRef}>
      <GoogleMap
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
      />
    </div>
  </UiBox>
  </>)
}