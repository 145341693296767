import React, {useContext, useEffect, useState} from "react";
import firebase from "firebase";
import {SensorsTable} from "./table/SensorsTable";
import {AuthContext} from "../../context/AuthContext";
import {UiButton} from "../ui/UiButton";
import {WelcomeRoutes} from "../../services/configuration";
import {Autocomplete, Grid,TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Sensor, SensorStatus, SensorExport} from "../../models/types/sensor";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const excelJS = require("exceljs");

export function Sensors() {
    const [sensors, setSensors] = useState<Sensor[]>([]);
    const [sensorsToShow, setSensorsToShow] = useState<Sensor[]>([]);
    const [search, setSearch] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [selectedFilterTags, setSelectedFilterTags] = useState<string[]>([]);
    const user = useContext(AuthContext);
    const db = firebase.firestore();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        getSensors();
    }, []);

    useEffect(() => {
        setSensorsToShow(sensors.filter(sensor => {
            if (selectedFilterTags.length > 0) {
                const foundTag = selectedFilterTags.map(tag => sensor?.tags?.includes(tag));
                if (selectedFilterTags.length !== foundTag.filter(Boolean).length) {
                    return false;
                }
            }

            if (search) {
                // Use 'name' if available, otherwise use 'label'
                const displayName = sensor.name || sensor.label;

                const nameMatch = displayName && displayName.toLowerCase().includes(search.toLowerCase());
                const idMatch = sensor.id && sensor.id.toLowerCase().includes(search.toLowerCase());

                if (!(nameMatch || idMatch)) {
                    return false;
                }
            }

            return true;
        }));
    }, [sensors, search, selectedFilterTags]);

    function toggleFilterTag(tag: string) {
        const currentSelectedTagList = [...selectedFilterTags];
        const index = currentSelectedTagList.indexOf(tag);
        if (index >= 0) {
            currentSelectedTagList.splice(index, 1);
        } else {
            currentSelectedTagList.push(tag);
        }
        setSelectedFilterTags(currentSelectedTagList);
    }

    function exportSensor() {
        getSensors();
        getSensorStatus();

    }

    function getSensors() {
        if (!user) {
            return;
        }
        db.collection('devices').where('users', 'array-contains', user.uid).onSnapshot(snapshot => {
            const sensorList: any[] = [];
            const tagList: Set<string> = new Set();
            snapshot.forEach(async doc => {
                const sensor: Sensor = doc.data() as Sensor;
                sensor.tags?.forEach((tag: string) => tagList.add(tag));
                sensorList.push(sensor);
            });
            setTags(Array.from(tagList.values()));
            setSensors(sensorList);
        });
    }

    async function getSensorStatus() {
        const sensExp: any[] = [];
        if (!user) {
             return;
        }
        let counter = 1;
        if(!selectedDate){
            enqueueSnackbar('Please select a from date', {variant: 'error'});
            return;
        }
        await Promise.all (
         sensorsToShow.map (async (sensor) => {
          const statuses: SensorStatus[] = (await db.collection(`devices/${sensor.id}/status`)
            .where('Timestamp', '>=', selectedDate)
            .where('Timestamp', '<=', selectedEndDate)
            .limit(300)
            .orderBy('Timestamp', 'desc')
            .get()).docs.map(doc => doc.data());

           statuses.forEach( (status) => {
            const sensorsExport: SensorExport = {
                  id: counter,
                  name: sensor.label,
                  tags: sensor.tags,
                  type: sensor.Type,
                  Serial: sensor.Serial,
                  AllowedCom: status.AllowedCom,
                  Distance: status.Distance,
                  CurrentVoltage: status.CurrentVoltage,
                  Timestamp: new Date(status?.Timestamp?.seconds * 1000+ status?.Timestamp?.nanoseconds / 1000000).toLocaleString(), //status.Timestamp,
                  Latitude: sensor.coordinates?.y.toString(),
                  Longitude: sensor.coordinates?.x.toString(),
             }

             sensExp.push(sensorsExport);
             counter++;

             delay (1000);

          });

        }));

        //console.log(sensExp);
        getExcelExport(sensExp);

    }

    function delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    function getDate(){
        let dt = new Date();
       return dt;
    }

    function getExcelExport(sensorExport: any[]) {

        const workbook = new excelJS.Workbook();  // Create a new workbook
        const worksheet = workbook.addWorksheet("Sensor Data"); // New Worksheet
        const path = "./files";  // Path to download excel

        worksheet.columns = [
          { header: "Id", key: "id", width: 10 },
          { header: "Name", key: "name", width: 10 },
          { header: "Tags", key: "tags", width: 10 },
          { header: "Sensor Type", key: "type", width: 10 },
          { header: "Sensor Serial", key: "Serial", width: 10 },
          { header: "Network", key: "AllowedCom", width: 10 },
          { header: "Level", key: "Distance", width: 10 },
          { header: "Battery", key: "CurrentVoltage", width: 10 },
          { header: "Last Online Time", key: "Timestamp", width: 10 },
          { header: "Latitude", key: "Latitude", width: 10 },
          { header: "Longitude", key: "Longitude", width: 10},
        ];

        sensorExport.forEach((value) => {
            console.log(value);
            worksheet.addRow([value.id, value.name, value.tags, value.type, value.Serial, value.AllowedCom, value.Distance, value.CurrentVoltage, value.Timestamp, value.Latitude, value.Longitude]);
        });

        worksheet.getRow(1).eachCell((cell: any) => {
          cell.font = { bold: true };
        });

        workbook.xlsx.writeBuffer().then((data: any) => {
          console.log("buffer");
          const blob = new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = "sensors_export.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
       });

    }

    if (!sensors.length) {
        return <div  style={{textAlign: 'center'}}>
            <div>You don't seem to have any sensors</div>
            <div style={{ marginTop: '2rem' }}><UiButton name="add device" onClick={() => navigate(WelcomeRoutes.NEW_SENSOR) } /></div>
        </div>;
    }

    return (<>
        <Grid container xs={12} md={12} lg={12}>
            <Grid item xs={6} sm={4} md={4} lg={12} style={{marginTop: '15px'}}>
                <h2>Sensors</h2>
            </Grid>
            <Grid item xs={6} sm={8} md={8} lg={12} style={{textAlign: 'left', marginTop: '20px', paddingRight:'20px'}} sx={{ paddingLeft:{xs:'0',md:'0',lg:'20px'}, display:{xs:'block',md:'block',lg:'block'}}}>
                    <TextField
                        variant="outlined"
                        label="Search"
                        value={search || ''}
                        onChange={(value) => setSearch(value.target.value)}
                        style={{width:'100%'}}
                    />
            </Grid>
        </Grid>
        <div style={{textAlign: 'right', margin: '20px'}}>
            <span style={{padding: '10px'}}>From: <DatePicker selected={selectedDate} onChange={date => setSelectedDate(date)} maxDate= {getDate()} dateFormat="dd/MM/yyyy" /></span>
            <span style={{padding: '10px'}}> To: <DatePicker selected={selectedEndDate} onChange={date => setSelectedEndDate(date)} maxDate= {getDate()} dateFormat="dd/MM/yyyy" /></span>
            <span style={{padding: '10px'}}><UiButton
                    name = "Export"
                    onClick={exportSensor}
            /></span>
        </div>
        <div style={{margin: '20px'}}>
        </div>
        <Grid style={{textAlign: 'left', margin: '20px'}} sx={{display:{xs:'none',md:'none',lg:'inline-block'}}}>
            <span style={{padding: '10px'}}>Filter sensor location tags: </span>
            {!tags.length && <span style={{padding: '10px'}}>none of your sensors has any location tags set</span>}
            {tags.map(tag => <span key={tag}>
                <span className="likeALink"
                      key={tag}
                      onClick={() => toggleFilterTag(tag)}
                      style={{
                          cursor: 'pointer',
                          borderRadius: '10px',
                          borderStyle: selectedFilterTags.indexOf(tag) >= 0 ? 'solid' : 'none',
                          borderWidth: '1px',
                          borderColor: 'green',
                          padding: '3px'
                      }}
                >{tag}</span> {' '}</span>)}
        </Grid>
        <Grid style={{textAlign: 'left', margin: '20px'}} sx={{display:{xs:'block',md:'block',lg:'none'}}}>
            <Autocomplete
                multiple
                value={selectedFilterTags}
                onChange={(event: any, newValue: string[]) => {
                    setSelectedFilterTags(newValue);
                }}
                id="multiple-tags"
                options={tags}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                    <TextField {...params} label="Location Tags Filter" placeholder="Location Tags Filter" />
                )}
                style={{paddingLeft: '10px'}}
             />
        </Grid>
        <SensorsTable sensors={sensorsToShow} setTags={setTags}/>
    </>);
}