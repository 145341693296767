import {UiBox} from "../../../ui/UiBox";
import React from "react";
import {Sensor, SensorStatus, sensorTypeMapping, SensorTypes, sensorTypeContainerMapping} from "../../../../models/types/sensor";
import {SensorShowStatusDoses} from "./doses/SensorShowStatusDoses";
import {SensorShowStatusCounter} from "./counter/SensorShowStatusCounter";
import {SensorShowStatusDistance} from "./distance/SensorShowStatusDistance";

interface Props {
    sensor: Sensor;
    sensorStatus: SensorStatus;
    sensorStatuses: SensorStatus[];
    changeData: (object: string, value: any) => void;
}

export function SensorShowStatus({ sensor, sensorStatus, sensorStatuses, changeData }: Props) {
    return <UiBox header="Status">
        {<SensorShowStatusDistance sensor={sensor} sensorStatus={sensorStatus} sensorStatuses={sensorStatuses} changeData={changeData} />}

    </UiBox>;
}