import {Grid} from "@mui/material";
import {UiBox} from "../../../../ui/UiBox";
import {UiTextField} from "../../../../ui/UiTextField";
import React from "react";
import {Sensor} from "../../../../../models/types/sensor";
import {UiDropDown} from "../../../../ui/UiDropDown";

interface Props {
    sensor: Sensor;
    changeData: (object: string, value: any) => void;
    rows?: boolean;
}

export function SensorShowSettingsGeneral({ sensor, changeData, rows }: Props) {

    const showReportingFrequencyWarning: boolean = !!(!sensor || (sensor.reportingFrequency && sensor.reportingFrequency < 60));

    const showReportingTimeWarning: boolean = !!(!sensor || (sensor.reportingFrequency && sensor.reportingTime === 'seconds' && sensor.reportingFrequency < 10));

    const dropdownOptions = [
        { label: 'Recycling Bin', value: 'recycling-bin' },
        { label: 'Paper Feeder', value: 'paper-feeder' },
        { label: 'Window Sensor', value: 'window-sensor' },
        { label: 'Dispenser', value: 'dispenser' }, 
        { label: 'Other Devices', value: 'other-devices' } 
    ];

    const dropdownTime = [
        { label: 'Minutes', value: 'minutes' },
        { label: 'Seconds', value: 'seconds' },
    ];

    return <UiBox header="Settings" margin={0}>
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <UiDropDown
                    label="Container type"
                    options={dropdownOptions}
                    value={sensor?.containerType}
                    onChange={(value) => changeData('containerType', value)}
                />
            </Grid>
            <Grid item xs={12}>
                <UiTextField
                    label="Updates frequency"
                    value={sensor?.reportingFrequency || ''}
                    onChange={(value) => changeData('reportingFrequency', value)}
                />
                {showReportingFrequencyWarning &&
                    <div style={{ fontSize: '.8rem', color: 'red' }}>Time is too short, battery life will decrease!</div>
                }
            </Grid>
            <Grid item xs={12}>
                <UiDropDown
                    label="Reporting Time"
                    options={dropdownTime}
                    value={sensor?.reportingTime}
                    onChange={(value) => changeData('reportingTime', value)}
                />
                {showReportingTimeWarning &&
                    <div style={{ fontSize: '.8rem', color: 'red' }}>Time is too short, please choose more than 10 seconds!</div>
                }
            </Grid>
            <Grid item xs={12}>
                <UiTextField
                    label="Network"
                    value={sensor?.Connection || ''}
                    onChange={(value) => changeData('Connection', value)}
                />
            </Grid>
            <Grid item xs={12}>
                <UiTextField
                    label="Latitude"
                    value={sensor?.coordinates.y || ''}
                    onChange={(value) => changeData('coordinatesY', value)}
                />
                <UiTextField
                    label="Longitude"
                    value={sensor?.coordinates.x || ''}
                    onChange={(value) => changeData('coordinatesX', value)}
                />
            </Grid>
        </Grid>
    </UiBox>;
}