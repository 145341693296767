import React from "react";

interface Props {
    name?: string;
    icon?: any;
    onClick?: any;
    style?: any;
}

export function UiButton({name, icon, onClick, style}: Props) {

    return <span
        style={{ ...style, textTransform: 'uppercase' }}
        className="likeALink"
        onClick={onClick}>
        {icon} {name}
    </span>;
}