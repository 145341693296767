import {TextField} from "@mui/material";
import React from "react";

interface Props {
    label: string;
    value: any;
    type?: string;
    disabled?: boolean;
    InputProps?: any;

    onChange?: (value: any) => void;
}

export function UiTextField({onChange, label, type, disabled, InputProps, value, ...rest}: Props) {
    return (
        <TextField
            fullWidth
            variant="standard"
            type={type}
            InputProps={InputProps}
            value={value}
            label={label}
            disabled={disabled}
            onChange={onChange}
            {...rest}
        />
    );
}