import firebase from "firebase";

export function convertToTimestamp(date?: firebase.firestore.Timestamp) {
    if (!date) {
        return '';
    }
    return date.toDate().toLocaleString('et-EE');
}

export function getDoorStatus(doorStatus?: number) {
    if (doorStatus === 1) {
        return 'Open';
    }
    return 'Close';
}


export function getOccupancy(occupancyStatus?: number) {
    if (occupancyStatus === 100) {
        return 'Vacant';
    }
    return 'Occupied';
}