import {InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

interface Props {
    label?: string;
    value?: string;
    options: any[];
    disabled?: boolean;
    InputProps?: any;
    onChange?: (value: any) => void;
}

export function UiDropDown({onChange, label, options, disabled, InputProps, value, ...rest}: Props) {
    return (
        <>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                style={{ width: '100%' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value || 'Type not defined'}
                label={label}
                onChange={onChange}
            >
                {!value && <MenuItem disabled value={"Type not defined"}>{"Type not defined"}</MenuItem> }
                {options.map((option, index) => {
                    return <MenuItem
                        value={option.value}
                        key={`menu-item-option-${index}`}
                    >{option.label}</MenuItem>;
                })}
            </Select>
        </>
    );
}