import React, {useState} from "react";
import {ReactComponent as Arrow} from '../../images/arrow.svg';
import {Container} from "@mui/material";

interface Props {
    header: string,
    children: any,
    closeable?: boolean,
    isClosed?: boolean,
    className?: string,
    margin?: number,
}

export function UiBox({header, className, children, margin, closeable = false, isClosed = false}: Props) {
    const [closed, setClosed] = useState<boolean>(isClosed);

    function toggle() {
        setClosed(!closed);
    }

    let currentStyle = {};
    if (!margin) {
        currentStyle = {margin: margin, marginTop: '30px'}
    }
    let maxWidth = true;
    if(header == "Status"){
        maxWidth = false;
    }

    return <div className='UiBox' style={currentStyle}>
        {closeable && <div
            style={{
                position: 'absolute',
                right: '0px',
                top: '0px',
                transform: `rotate(${closed ? 0 : 180}deg)`,
                cursor: 'pointer',
            }}
            onClick={toggle}
        ><Arrow style={{height: '40px', verticalAlign: 'middle'}}/>
        </div>}
        <div className="UiBoxHeader">{header}</div>
        <Container maxWidth={maxWidth ? undefined : false} className={className} style={{
            display: `${closed ? 'none' : 'inherit'}`, paddingTop: '15px', paddingBottom: '15px'
        }}>{children}</Container>
    </div>;
}
