import LogoIbiot from '../images/logo-header-ibiot.svg';
import LogoKastell from '../images/logo-header-k-3000.svg';

export function getServiceUrl(url: string) {

  if(process.env.REACT_APP_USE_PRODUCTIONDB === 'true') {
      return `https://sensors.ibiot.io/${url}`;
  }
  else{
    return `https://dev.sensors.ibiot.io/${url}`
    // return `http://localhost:9000/${url}`;
  }
}

export enum WelcomeRoutes {
    LOGIN = '/login',
    PROFILE = '/profile',
    ROOT = '/',
    SENSOR_LIST = '/sensor-list',
    SENSOR_DETAILS = '/sensor-details',
    SENSOR_MAP = '/sensor-map',
    NEW_SENSOR = '/sensor-details/new',
    DEVELOPERS = '/developers',
}

export const AppTheme = {
    kastell: {
        logo: LogoKastell,
        backgroundColor: '#BF272E',
        video: false,
    },
    ibiot: {
        logo: LogoIbiot,
        backgroundColor: 'rebeccapurple',
        video: true,
    },
};

function getValidatedAppSkin(skin: string | undefined): keyof typeof AppTheme {
    if (skin && AppTheme.hasOwnProperty(skin)) {
      return skin as keyof typeof AppTheme;
    } else {
      return 'ibiot';
    }
  }

export const AppSkin = getValidatedAppSkin(process.env.REACT_APP_APPSKIN);

