import React from "react";
import {UiBox} from "../../ui/UiBox";
import {NewSensorContent} from "./content/NewSensorContent";

export function NewSensor() {
    return (<>
        <h2>Add new sensors to your list</h2>
        <UiBox header="New sensor registration" className="sensor">
            <NewSensorContent/>
        </UiBox>
    </>);
}