import React, {useState} from "react";
import {auth, Providers} from "../../firebase";
import {Container, Grid, IconButton, InputAdornment} from "@mui/material";
import {useSnackbar} from "notistack";
import {ReactComponent as Linnapanoraam} from "../../images/linnapanoraam.svg";
import {ReactComponent as SignUp} from "../../images/person-plus.svg";
import {ReactComponent as Google} from "../../images/google.svg";
import {UiTextField} from "../ui/UiTextField";
import {ReactComponent as SaveCheck} from "../../images/check-circle.svg";
import {UiButton} from "../ui/UiButton";
import {checkIfUserExists, registerUser} from "../../services/user";
import {SignInWithSocialMedia} from "../../provider/AuthProvider";
import firebase from 'firebase';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {UiSpinner} from "../ui/UiSpinner";
import ReactPlayer from "react-player";
import {AppSkin, AppTheme} from "../../services/configuration";

export function Login() {
    const [showName, setShowName] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {enqueueSnackbar} = useSnackbar();


    async function startOnBoarding() {
        if (!showName) {
            setShowName(true);
            return;
        }

        setIsLoading(true);
        await createAccount();
        setIsLoading(false);
    }

    async function signInWithSocialMedia(provider: firebase.auth.AuthProvider) {
        setIsLoading(true);
        try {
            const result = await SignInWithSocialMedia(provider);
            if (result.user?.email && !(await checkIfUserExists(result.user.email))) {
                const createUserWelcome = firebase.app().functions('europe-west1').httpsCallable('createUserWelcome');
                const accountCreationResult = await createUserWelcome({ name: result.user.displayName, email: result.user.email, userId: result.user.uid });
                if (accountCreationResult.data.result === 'error') {
                    enqueueSnackbar('Failed to create your Ibiot account', {variant: 'error'});
                    await auth.signOut();
                } else {
                    enqueueSnackbar('Registered your account in Ibiot', {variant: 'success'});
                }
            }
            enqueueSnackbar('You have been logged in with google', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Failed to log in to ibiot with your account', {variant: 'error'});
        }
        setIsLoading(false);
    }

    async function createAccount() {
        if (!email || !password || !name) {
            enqueueSnackbar('You should fill all the fields (name, email and password in order to on board in ibiot)', {variant: 'error'});
            return;
        }
        try {
            const output = await registerUser(email, password, name);
            if (output.data.result === 'error') {
                enqueueSnackbar(`Failed to create the account? ${output.data.message}`, {variant: 'error'});
                return;
            }
            await auth.signInWithEmailAndPassword(email, password);
            enqueueSnackbar('Account has been created', {variant: 'success'});
        } catch (error: any) {
            enqueueSnackbar(`Failed to create the account? ${error?.message}`, {variant: 'error'});
        }
    }

    async function signIn() {
        try {
            await auth.signInWithEmailAndPassword(email, password);
            enqueueSnackbar('You have been logged in', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Something went wrong?', {variant: 'error'});
        }
    }

    return (<div className="App">
        {isLoading && <UiSpinner />}
        <header className="App-header">
            <div className="header-logo"><img src={AppTheme[AppSkin]['logo']} alt="react logo" /></div>
        </header>
        <Container>
            <Grid container className="content">
                <Grid item lg={8} md={12}>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop: '2rem'}}>
                            <div className="loginHeading">THE CONNECTED THINGS HUB</div>
                            <div style={{ marginLeft: '30px', width: '450px', }}>
                                <div>Sign for the on-boarding process or create your account</div>
                                {showName && <div style={{marginTop: '1rem'}}>
                                    <UiTextField
                                        label="Your name"
                                        value={name || ''}
                                        onChange={(event => setName(event.target.value))}
                                    />
                                </div>}

                                <div style={{ marginTop: '1rem', }}>
                                    <UiTextField
                                        label="Email"
                                        value={email || ''}
                                        onChange={(event => setEmail(event.target.value))}
                                    />
                                </div>

                                <div style={{ marginTop: '1rem', }}>
                                    <UiTextField
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password || ''}
                                        onChange={(event => setPassword(event.target.value))}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>)
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: '2rem', }}>
                                    <span style={{textAlign: 'right', marginRight: '30px'}}>
                                        <UiButton
                                            name="sign up"
                                            icon={<SignUp style={{height: '20px', verticalAlign: 'middle'}}/>}
                                            onClick={startOnBoarding}
                                        />
                                    </span>
                                            {!showName && <>
                                    <span style={{textAlign: 'right', marginRight: '30px'}}>
                                        <UiButton
                                            name="sign in"
                                            icon={<SaveCheck style={{height: '20px', verticalAlign: 'middle'}}/>}
                                            onClick={signIn}
                                        />
                                    </span>
                                                <span style={{textAlign: 'right', marginRight: '30px'}}>
                                    <UiButton
                                        name="google"
                                        icon={<Google style={{height: '20px', verticalAlign: 'middle'}}/>}
                                        onClick={() => signInWithSocialMedia(Providers.google)}
                                    />
                                    </span>
                                            </>}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={12}>
                    {AppTheme[AppSkin]['video'] &&
                        <div style={{marginTop: '1rem', marginRight: '1rem'}}>
                            <ReactPlayer url='https://youtu.be/-t6Zyg8U1cA' playing={false} width="100%"
                                         style={{backgroundColor: 'transparent !important'}}/>
                        </div>
                    }
                </Grid>
            </Grid>
        </Container>
        <div style={{
            position: 'fixed', bottom: '0px', width: '100%', zIndex: -1,
        }}>
            <div
                style={{
                    width: '100%', position: 'absolute', bottom: '16px', textAlign: 'center',
                }}
            ><Linnapanoraam style={{width: '60%'}}/></div>

            <div
                style={{
                    backgroundColor: '#FFBC00', width: '100%', height: '20px', position: 'absolute', bottom: '0px',
                }}
            ></div>
        </div>
    </div>);
}
