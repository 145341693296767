import {Grid} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {FirestoreSensorClass} from "../../../../models/firestore-sensor";
import {useSnackbar} from "notistack";
import {AuthContext} from "../../../../context/AuthContext";
import {ReactComponent as SaveCheck} from "../../../../images/check-circle.svg";
import {confirmSensorPIN} from "../../../../microservices/sensors";
import {UiTextField} from "../../../ui/UiTextField";
import {UiButton} from "../../../ui/UiButton";
import {Link, useParams} from "react-router-dom";
import {insertSensorLog} from "../../../../services/sensor";
import firebase from "firebase";
import {Sensor} from "../../../../models/types/sensor";
import {useNavigate} from "react-router-dom";

export function NewSensorContent() {
    const [sensorId, setSensorId] = useState<string>('');
    const [sensorPIN, setSensorPIN] = useState<string>('');
    const [sensorClaimed, setSensorClaimed] = useState<boolean>(false);
    const {enqueueSnackbar} = useSnackbar();
    const user = useContext(AuthContext);
    const navigate = useNavigate();


    const params = useParams();
    useEffect(() => {
        setSensorId(params.sensorId || '');
        setSensorPIN(params.pin || '');
    }, [params]);

    async function claimSensor() {
        if (sensorId && user) {
            try {
                if (!await confirmSensorPIN(sensorId, sensorPIN)) {
                    enqueueSnackbar('Entered PIN does not match the sensors', {variant: 'error'});
                    return;
                }
                const firestoreDevice: Sensor = await FirestoreSensorClass.getSensor(sensorId);
                if (!firestoreDevice.users) {
                    firestoreDevice.users = [];
                }
                if (firestoreDevice.users.includes(user.uid)) {
                    enqueueSnackbar('You already have added this sensors to your log-list', {variant: 'error'});
                    return;
                }
                firestoreDevice.users.push(user.uid);
                if (!firestoreDevice.sensorClaimedAt) {
                    firestoreDevice.sensorClaimedAt = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
                }
                await FirestoreSensorClass.updateSensor(firestoreDevice);
                const log = {
                    createdAt: new Date(),
                    maintenance: `user: ${user.uid}, claimed sensor ${sensorId}`,
                    reportedBy: user.uid,
                    station: sensorId,
                    type: 'claim',
                };
                await insertSensorLog(sensorId, log);
                enqueueSnackbar('The sensors is yours', {variant: 'success'});
                setSensorClaimed(true)
            } catch (error) {
                enqueueSnackbar('Failed to claim the sensors, probably it does not exist', {variant: 'error'});
            }
        } else {
            enqueueSnackbar('Please fill the fields in order to claim the sensor-show', {variant: 'error'});
        }
    }

    return (<>
        <Grid container>
            <Grid item xs={12} md={6} padding={1}>
                <UiTextField
                    label="Sensor id"
                    value={sensorId || ''}
                    onChange={(event) => setSensorId(event.target.value.trim())}
                />
            </Grid>
            <Grid item xs={12} md={6} padding={1}>
                <UiTextField
                    label="PIN"
                    value={sensorPIN || ''}
                    onChange={(event) => setSensorPIN(event.target.value.trim())}
                />
            </Grid>
            <Grid container direction={'row-reverse'}>
                <Grid xs={12} md={3} padding={1}>
                    <div style={{textAlign: 'right', marginBottom: '2rem', marginTop: '2rem'}}>
                        <UiButton onClick={claimSensor}  name="Claim the sensor"
                                icon={<SaveCheck style={{height: '20px', verticalAlign: 'middle'}}/>}
                        />
                    </div>
                </Grid>
                <Grid xs={12} md={3} padding={1}>
                    {sensorClaimed && <div style={{textAlign: 'right', marginBottom: '2rem', marginTop: '2rem'}}>
                        <UiButton onClick={() => {navigate(`/sensor-details/${sensorId}`)}} name="View claimed sensor"
                                icon={<SaveCheck style={{height: '20px', verticalAlign: 'middle'}}/>}
                                
                        />
                    </div>}
                </Grid>
            </Grid>
        </Grid>
    </>);
}