import React, {ElementType} from "react";
import {Checkbox} from "@mui/material";

interface Props {
    label: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: (value: any) => void;
}

export function UiCheckBox({onChange, label, disabled, checked}: Props) {
    return (
        <Checkbox
            disabled={disabled}
            onChange={onChange}
            checked={checked}
            color="primary"
        />
    );
}