import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

let firebaseConfig = {
    apiKey: "AIzaSyB5GDvU3jW7kZA12OfEVQBf04NhOlgJjTg",
    authDomain: "zap-testserver.firebaseapp.com",
    projectId: "zap-testserver",
    storageBucket: "zap-testserver.appspot.com",
    messagingSenderId: "192569188679",
    appId: "1:192569188679:web:0f20e138b249641ad9ac62",
  };

if(process.env.REACT_APP_USE_PRODUCTIONDB === 'true') {
    firebaseConfig = {
        apiKey: 'AIzaSyB08elG7gU-850702NSCZEUDLJ5MK1cASc',
        authDomain: 'zap-server-317514.firebaseapp.com',
        projectId: 'zap-server-317514',
        storageBucket: 'zap-server-317514.appspot.com',
        messagingSenderId: '330980087349',
        appId: '1:330980087349:web:7bc97890b0658c2516037c',
    };
} 
const _firebase = firebase.initializeApp(firebaseConfig);

/*
if (process.env.REACT_APP_FIREBASE_ENV === 'development') {
    window.firebase = _firebase;
    _firebase.firestore().useEmulator('localhost', 8080);
    _firebase.auth().useEmulator('http://localhost:9099');
    _firebase.functions('europe-west1').useEmulator('localhost', 5001);
}
 */

export const Providers = {
    google: new firebase.auth.GoogleAuthProvider(),
    facebook: new firebase.auth.FacebookAuthProvider(),
};

export const auth = firebase.auth();
export default _firebase;
