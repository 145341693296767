import React, {useContext, useEffect, useState} from "react";
import {Container, Grid} from "@mui/material";
import {UiButton} from "../ui/UiButton";
import {useNavigate} from "react-router-dom";
import {AppSkin, AppTheme, WelcomeRoutes} from "../../services/configuration";
import {ReactComponent as Arrow} from '../../images/arrow.svg';
import {NewSensorContent} from "../sensors/new-sensor/content/NewSensorContent";
import ReactPlayer from "react-player";
import {AuthContext} from "../../context/AuthContext";
import {getUserProfile, updateUserProfile} from "../../services/user";
import {UserProfile} from "../../models/types/userProfile";

interface Step {
    name: string,
    hidden?: boolean,
    message: any,
    show: string,
}

export default function Welcome() {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const user = useContext(AuthContext);

    useEffect(() => {
        loadUserProfile();
    }, [user]);

    async function loadUserProfile() {
        if (user) {
            const profile = await getUserProfile(user.uid);
            setCurrentStep(profile.currentWelcomeStep || 0);
            setUserProfile(profile);
        }
    }

    const [steps, setSteps] = useState<Step[]>([{
        name: 'video', show: 'inherit', hidden: true, message: <div>
            {AppTheme[AppSkin]['video'] && <ReactPlayer url='https://youtu.be/-t6Zyg8U1cA' playing={false} width='100%' />}
        </div>,
    }, {
        name: 'Ensure LoRaWan coverage', show: 'none', message: <>
            <p>The ibiot sensors works with the public LoRaWan network. Make sure that
                you have coverage in your location to use the sensor. If public networks
                are out of reach, you should also get a gateway and connect it to the
                internet to create coverage. You can buy your gateway from shop.ibiot.io.
                You can also connect to the public network. To see if you have public
                network coverage in your area, visit the
                <a href="https://www.thethingsnetwork.org/map">official LoRaWan coverage map</a>.</p>
            <p>Ibiot sensors have EU and US frequency models. Be sure to order the
                correct one for your region! It is not allowed to use the wrong frequency!
                <a href="https://www.thethingsnetwork.org/docs/lorawan/frequencies-by-country">Check frequency plans by country</a></p>
        </>
    }, {
        name: 'Set up your sensor', show: 'none', message: <>
            <p>When you have your sensor at hand, follow these simple steps to set it up
                and connect to the ibiot dashboard.</p>
            <ul>
                <li> <b>ToF Sensor: </b> 
                    <ul>
                        <li>Take your sensor from your package. The sensor is already on and will be
                            connected to the network (helium by default), if available.</li>
                        <li>
                            Place the sensor into the designated area.
                        </li>
                        <li>
                            Verify that the sensor is connected to the network and is working.
                        </li>

                    </ul>

                </li>
                <li> <b>C-Sensor:</b>
                    <ul>
                        <li>Take your sensor from the package. Do not throw anything away! Inside
                            the box, you will also find the following items:
                        <ul>
                            <li>Ibiot C-sensor</li>
                            <li>Battery</li>
                            <li>Log-in credentials</li>
                        </ul>
                        </li>
                        <li>Remove the battery cover from the sensor. Insert the AA battery into the
                            sensor and reattach the cover.</li>
                        <li>Insert the sensor into the unconnected device</li>
                        <li>Verify that the device works.</li>

                    </ul>
                </li>
                
            </ul>
        </>,
    }, {
        name: 'Claim your sensor', show: 'none', message: <>
            <p>We have created a handy onboarding environment to make ibiot sensors
                easy to use for anyone.</p>
            <p>To complete the onboarding, you need to scan the QR code from the
                sensor cover or insert codes from the attached credentials leaflet. To claim
                the sensor, you need to have a registered account! However, as you are
                here, you already have it.</p>
            <p>You can claim your sensor here.</p>
            <p><NewSensorContent/></p>
        </>,
    }, {
        name: 'Configure settings', show: 'none', message: <>
            <p>To set up the sensor, go to https://welcome.ibiot.io/sensor-list and enter
                the sensor id into the search box.</p>
            <p>Here, you have the option to change the sensor name. In addition, you can
                specify the consumable used by the device. Moreover, set a specific
                reporting frequency - how often the device sends data to the hub.</p>
            <p>Prolonging reporting frequency will also prolong battery life and vice
                versa.</p>
            <p>By scrolling down on the page, there are options to set specific alert levels
                when the battery or consumable reaches a certain level, so you can make
                sure to never run out of anything.</p>
            <p>You can give the sensor-specific tags. Tags are a useful tool, as they allow
                tagging different devices together to a particular location, like a house, an
                office floor, or a street. In addition, you can enter several tags for one
                sensor.</p>
            <p>On the left side of the header, You can see consumables and a battery bar.
                The refill button should be used when the consumable has been changed,
                as it resets the consumable-meter to the maximum level.</p>
            <p>If everything is customized to your liking, hit the save button. And you are
                all done.</p>
            <p>On the developers page Ibiot - onboarding you can set up your API
                connection to connect it to your dashboard.</p>
        </>,
    },]);
    const navigate = useNavigate();

    function showInformationToggle(index: number): void {
        const newSteps = [...steps];
        newSteps.forEach(step => step.show = 'none');
        newSteps[index].show = newSteps[index].show === 'none' ? 'inherit' : 'none';
        setSteps(newSteps);
        setCurrentStep(index);
    }

    function isLastStep() {
        return currentStep === steps.length - 1;
    }

    function isFirstStep() {
        return currentStep === 0;
    }

    async function showInformationNext(previous = false): Promise<void> {
        if (!userProfile || !user) {
            return;
        }

        const currentStepIndex = currentStep;
        if (previous) {
            showInformationToggle(currentStepIndex - 1);
            await updateUserProfile(user.uid, { ...userProfile, currentWelcomeStep: currentStepIndex - 1 })
        } else {
            if (currentStepIndex >= 0 && currentStepIndex < steps.length - 1) {
                showInformationToggle(currentStepIndex + 1);
                await updateUserProfile(user.uid, { ...userProfile, currentWelcomeStep: currentStepIndex + 1 })
            } else if (isLastStep()) {
                navigate(WelcomeRoutes.SENSOR_LIST);
            }
        }
    }

    return (<div className="App">
        <Container maxWidth="md">
            <h2 style={{textAlign: 'center'}}>Welcome to Ibiot.io on-boarding experience</h2>
            <Grid container justifyContent="center" alignItems="top" alignSelf="center">
                <Grid item xs={12} md={3} style={{position: 'relative'}}>
                    {steps.map((step, index) => {
                        if (step.hidden) {
                            return null;
                        }
                        return <div
                            key={index}
                            onClick={() => currentStep > index - 2 && showInformationToggle(index)}
                            className="welcomeStepBox"
                            style={{
                                backgroundColor: currentStep === index ? '#e8e8e8' : (currentStep > index ? '#c8ff84' : ''),
                                cursor: currentStep > index - 2 ? 'pointer' : '',
                            }}>
                            <div className="step">{step.name}</div>
                            {steps.length - 1 > index && <div className="arrow"><Arrow style={{height: '30px'}}/></div>}
                            <div style={{
                                position: 'absolute',
                                height: '30px',
                                width: '30px',
                                textAlign: 'center',
                                borderColor: '#FFBC00',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderRadius: '20px',
                                backgroundColor: '#ffffff',
                                top: '-10px',
                                left: '-20px',
                            }}>
                                <div style={{ marginTop: '5px', fontWeight: 'bold', color: '#FFBC00' }}>{index}</div>
                            </div>
                        </div>
                    })}
                    <div style={{position: 'relative', zIndex: '1', top: '10px', right: '0px', width: '100%', textAlign: 'right' }}>
                        {!isFirstStep() && <UiButton style={{ marginLeft: '10px' }} name="previous" onClick={() => showInformationNext(true)}/>}
                        <UiButton style={{ marginLeft: '10px' }} name={isLastStep() ? 'finish' : 'next'} onClick={() => showInformationNext(false)}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={9} className="welcomeInformationBox">
                    <div className="information">
                        {currentStep === 0 && steps[currentStep].message}
                        {currentStep > 0 && <div className="information-content">{steps[currentStep].message}</div>}
                    </div>
                </Grid>
            </Grid>
        </Container>
    </div>);
}


// <CardMedia
//             component="video"
//             image={"https://welcome.ibiot.io/welcome-video.webm"}
//             autoPlay
//         ></CardMedia>