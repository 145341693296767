import {Grid, IconButton, InputAdornment} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import firebase from "firebase";
import {AuthContext} from "../../context/AuthContext";
import {UiBox} from "../ui/UiBox";
import {UserProfile} from "../../models/types/userProfile";
import {UiTextField} from "../ui/UiTextField";
import {UiButton} from "../ui/UiButton";
import {ReactComponent as SaveCheck} from "../../images/check-circle.svg";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export function Profile() {
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const [displayName, setDisplayName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {enqueueSnackbar} = useSnackbar();
    const user = useContext(AuthContext);
    const db = firebase.firestore();

    useEffect(() => {
        getUserProfile();
        setDisplayName(user?.displayName || '');
    }, [user]);

    async function getUserProfile() {
        if (!user) {
            return;
        }

        setDisplayName(user.displayName || '');


        db
            .collection('users')
            .doc(user.uid)
            .onSnapshot(snapshot => {
                const userData: UserProfile = snapshot.data() as UserProfile;
                setUserProfile(userData);
            });
    }

    function changeData(object: string, value: any) {
        if (user) {
            if (object === 'displayName') {
                setDisplayName(value.target.value);
            } else if (object === 'password') {
                setPassword(value.target.value);
            }
        }

        if (userProfile) {
            const changedUser: UserProfile = {...userProfile};
            if (object === 'displayName') {
                changedUser.name = value.target.value;
            }
            setUserProfile(changedUser);
        }
    }

    async function updateProfile() {
        try {
            if (!user || !userProfile) {
                enqueueSnackbar('You have to be logged in to save', {variant: 'error'});
                return;
            }

            if (password) {
                try {
                    await user.updatePassword(password);
                } catch (error: any) {
                    enqueueSnackbar(`Failed to save password: ${error.message}`, {variant: 'error'});
                    return;
                }
            }

            await user.updateProfile({displayName});

            await db
                .collection('users')
                .doc(user.uid)
                .update(userProfile);
            enqueueSnackbar('Saved successfully', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Failed to save', {variant: 'error'});
        }
    }

    return (<>
        <h2>My profile</h2>
        <UiBox header="User Configuration">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <UiTextField
                        label="My name"
                        value={displayName || ''}
                        onChange={(value) => changeData('displayName', value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <UiTextField
                        label="My username / email"
                        disabled
                        value={user?.email || ''}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <UiTextField
                        type={showPassword ? 'text' : 'password'}
                        label="Change Password"
                        value={password || ''}
                        onChange={(value) => changeData('password', value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container margin={1}>
                <Grid item xs={12}>
                    <div style={{textAlign: 'right', marginTop: '30px', marginBottom: '10px'}}>
                        <UiButton
                            name="save"
                            icon={<SaveCheck style={{height: '20px', verticalAlign: 'middle'}}/>}
                            onClick={updateProfile}
                        />
                    </div>
                </Grid>
            </Grid>
        </UiBox>
    </>)

}