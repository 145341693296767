import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {getApiSensor, getApiURL} from "../../../microservices/api";
import {getUserProfile} from "../../../services/user";
import {UiBox} from "../../ui/UiBox";
import {UserProfile} from "../../../models/types/userProfile";
import {Link} from "react-router-dom";
import {WelcomeRoutes} from "../../../services/configuration";

interface Props {
    sensorId?: string;
}

export function ApiSensor({ sensorId }: Props) {
    const [apiSensor, setApiSensor] = useState<any>({});
    const [userProfile, setUserProfile] = useState<UserProfile>({} as UserProfile);
    const user = useContext(AuthContext);

    useEffect(() => {
        getSensor();
    }, [sensorId]);

    if (!sensorId) {
        return null;
    }

    async function getSensor() {
        if (!user || !sensorId) {
            return;
        }

        const userProfile = await getUserProfile(user.uid);
        setUserProfile(userProfile);
        const apiResponse = await getApiSensor(sensorId, userProfile.email, userProfile.apiKey);
        setApiSensor(apiResponse);
    }

    if (!userProfile.apiKey) {
        return <p>Generate API key, before we can show the output! <Link to={WelcomeRoutes.DEVELOPERS}>Go to Developers section.</Link></p>;
    }

    if (!sensorId) {
        return null;
    }

    return <UiBox header="API output" closeable isClosed>
        <pre>api URL: {getApiURL('sensor', userProfile.email, userProfile.apiKey, sensorId)}</pre>
        <pre style={{ textAlign: 'left', borderStyle: 'solid', borderColor: 'red', borderWidth: '1px', padding: '5px' }}>{JSON.stringify(apiSensor, null, 4) }</pre>
    </UiBox>;
}