import Firebase from "../firebase";
import {Sensor} from "./types/sensor";

const fireStore = Firebase.firestore();
const collection = fireStore.collection('devices');

export class FirestoreSensorClass {
    static async updateSensor(data: Sensor): Promise<any> {
        return collection.doc(data.id).update(data);
    }

    static async deleteSensor(id: string): Promise<void> {
        return collection.doc(id).delete();
    }

    static async getSensor(id: string): Promise<Sensor> {
        const sensor = await collection.doc(id).get();
        if (sensor.exists) {
            const station = {
                ...sensor.data() as Sensor
                };

            station.id = sensor.id;

            return station;
        } else {
            return {} as Sensor;
        }
    }
}
