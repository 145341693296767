import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import { SensorAlerts, SensorAlertType, SensorSupplies, updateSensorData} from "../../../microservices/sensors";
import {useParams, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import firebase from "firebase";
import {AuthContext} from "../../../context/AuthContext";
import {ReactComponent as SaveCheck} from '../../../images/check-circle.svg';
import {isEmpty, isNumber, isNaN} from 'lodash';
import {ApiSensor} from "../../api/sensor/ApiSensor";
import {UiBox} from "../../ui/UiBox";
import {UiTextField} from "../../ui/UiTextField";
import {UiButton} from "../../ui/UiButton";
import {
    calculateSensorLiquidLevel,
    fixSensorRefill,
    insertSensorLog,
} from "../../../services/sensor";
import {WelcomeRoutes} from "../../../services/configuration";
import {ReactComponent as Delete} from "../../../images/recycle-bin.svg";
import {FirestoreSensorClass} from "../../../models/firestore-sensor";
import {Sensor, SensorLog, SensorStatus} from "../../../models/types/sensor";
import {SensorShowSettings} from "./settings/SensorShowSettings";
import {SensorShowStatus} from "./status/SensorShowStatus";
import {SensorShowStatusLogList} from "./status/log-list/SensorShowStatusLogList";
import {SensorMessagingCenter} from "./messaging-center/SensorMessagingCenter";

export function SensorShow() {
    const [sensor, setSensor] = useState<Sensor>({} as Sensor);
    const [sensorStatus, setSensorStatus] = useState<SensorStatus>({});
    const [sensorStatuses, setSensorStatuses] = useState<SensorStatus[]>([]);
    const [sensorLogs, setSensorLogs] = useState<SensorLog[]>([]);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const {enqueueSnackbar} = useSnackbar();
    const user = useContext(AuthContext);
    const db = firebase.firestore();
    const navigate = useNavigate();

    const params = useParams();
    const {sensorId} = params;

    const DEFAULT_VALUES = {
        doses: {
            critical: 20,
            empty: 5,
        },
        battery: {
            critical: 1.4,
            empty: 1.2,
        },
        reportingFrequency: 60,
        reportingTime: 'minutes',
        maxLiquid: 1200,
    }

    useEffect(() => {
        getSensor();
        getSensorStatus();
    }, [user, sensorId]);

    useEffect(() => {
        const liquidLevel = calculateSensorLiquidLevel(sensor, {});

        if (sensor && liquidLevel.percentage > 100) {
            fixSensorRefill(sensor);
        }
    }, [sensor]);

    function goToSensors() {
        navigate(WelcomeRoutes.SENSOR_LIST);
    }

    function getSensorStatus() {
        if (!sensorId) {
            return;
        }
        db.collection(`devices/${sensorId}/status`)
            .where('MessageType', '==', 'regular')
            .orderBy('Timestamp', 'desc')
            .limit(1)
            .onSnapshot(snapshot => {
                snapshot.docChanges().forEach(change => {
                    if (change.type === 'added') {
                        setSensorStatus(change.doc.data());
                        getSensorStatuses();
                    }
                });
            });
    }

    async function getSensorStatuses() {
        if (!sensorId) {
            return;
        }

        const statuses: SensorStatus[] = (await db.collection(`devices/${sensorId}/status`)
            .orderBy('Timestamp', 'desc')
            .limit(30)
            .get()).docs.map(status => status.data());
        setSensorStatuses(statuses);
    }

    function getSensor() {
        if (!user) {
            return;
        }

        if (sensorId) {
            db
                .collection('devices')
                .where('users', 'array-contains', user.uid)
                .where('id', '==', sensorId)
                .onSnapshot(snapshot => {
                    snapshot.forEach(async doc => {
                        const sensor: Sensor = doc.data() as Sensor;

                        setSensorLogs((await doc.ref
                            .collection('logs')
                            .orderBy('createdAt', 'desc')
                            .limit(10)
                            .get()).docs.map(log => log.data() as SensorLog));

                        if (isEmpty(sensor.alerts?.battery)) {
                            if (isEmpty(sensor.alerts)) {
                                sensor.alerts = {};
                                sensor.alerts.battery = DEFAULT_VALUES.battery;
                            } else {
                                sensor.alerts.battery = DEFAULT_VALUES.battery;
                            }
                        }
                        if (isEmpty(sensor.alerts?.doses)) {
                            if (isEmpty(sensor.alerts)) {
                                sensor.alerts = {};
                                sensor.alerts.doses = DEFAULT_VALUES.doses;
                            } else {
                                sensor.alerts.doses = DEFAULT_VALUES.doses;
                            }
                        }

                        if (isEmpty(sensor.reportingFrequency)) {
                            sensor.reportingFrequency = DEFAULT_VALUES.reportingFrequency;
                        }
                        if (isEmpty(sensor.reportingTime)){
                            sensor.reportingTime = DEFAULT_VALUES.reportingTime;
                        }
                        if (isEmpty(sensor.coordinates)) {
                            sensor.coordinates = {
                                x:null,
                                y:null,
                                wkid:4326 //Google uses WGS84
                            };
                        }

                        if (isEmpty(sensor?.supplies?.maxLiquid)) {
                            if (isEmpty(sensor.supplies)) {
                                sensor.supplies = {} as SensorSupplies;
                                sensor.supplies.maxLiquid = DEFAULT_VALUES.maxLiquid;
                            } else {
                                sensor.supplies.maxLiquid = DEFAULT_VALUES.maxLiquid;
                            }
                        }

                        setSensor(sensor);
                        getSensorStatuses();
                    });
                });
        }
    }

    async function saveSensor() {
        try {
            if (!user) {
                enqueueSnackbar('You have to be logged in to save', {variant: 'error'});
                return;
            }
            if (sensor.reportingTime === 'seconds' && sensor.reportingFrequency <= 10){
                enqueueSnackbar('Please select reporting frequency time for more than 10 seconds', {variant: 'error'});
                return;
            }
            await updateSensorData(sensor.id, sensor, user);
            enqueueSnackbar('Saved successfully', {variant: 'success'});
            //goToSensors();
        } catch (error) {
            enqueueSnackbar('Failed to save', {variant: 'error'});
        }
    }

    function changeData(object: string, value: any) {
        const changedSensor = {...sensor};

        if (isEmpty(changedSensor.alerts)) {
            changedSensor.alerts = {} as SensorAlerts;
        }
        if (changedSensor.alerts && isEmpty(changedSensor.alerts?.doses)) {
            changedSensor.alerts.doses = {} as SensorAlertType;
        }
        if (changedSensor.alerts && isEmpty(changedSensor.alerts.battery)) {
            changedSensor.alerts.battery = {} as SensorAlertType;
        }

        if (['dosesCritical', 'dosesEmpty', 'batteryCritical', 'batteryEmpty', 'reportingFrequency','coordinatesY','coordinatesX'].includes(object)) {
            const numericValue = checkIsValidNumber(value.target.value);
            if (numericValue === null) {
                return;
            }

            if (object === 'dosesCritical') {
                if (changedSensor.alerts && changedSensor.alerts.doses && (numericValue === '' || numericValue <= 100)) {
                    changedSensor.alerts.doses.critical = value.target.value;
                }
            } else if (object === 'dosesEmpty') {
                if (changedSensor.alerts && changedSensor.alerts.doses && (numericValue === '' || numericValue <= 100)) {
                    changedSensor.alerts.doses.empty = value.target.value;
                }
            } else if (object === 'batteryCritical') {
                if (changedSensor.alerts && changedSensor.alerts.battery) {
                    changedSensor.alerts.battery.critical = value.target.value;
                }
            } else if (object === 'batteryEmpty') {
                if (changedSensor.alerts && changedSensor.alerts.battery) {
                    changedSensor.alerts.battery.empty = value.target.value;
                }
            } else if (object === 'reportingFrequency') {
                changedSensor.reportingFrequency = value.target.value;
            }
            else if (object === 'coordinatesY') {
                changedSensor.coordinates.y = value.target.value // Previously was Number(value.target.value), but this always deleted the . while typing
                changedSensor.coordinates.wkid = 4326 //Google uses WGS84

            }
            else if (object === 'coordinatesX') {
                changedSensor.coordinates.x = value.target.value // Previously was Number(value.target.value), but this always deleted the . while typing

            }
        }

        if (!changedSensor.supplies) {
            changedSensor.supplies = {} as SensorSupplies;
        }

        if (object === 'label') {
            changedSensor.label = value.target.value;
        } else if (object === 'tags') {
            changedSensor.tags = value;
        } else if (object === 'liquidType') {
            changedSensor.supplies.liquid = value.target.value;
        } else if (object === 'batteryType') {
            changedSensor.supplies.battery = value.target.value;
        } else if (object === 'maxLiquid') {
            changedSensor.supplies.maxLiquid = value.target.value;
        } else if (object === 'containerType') {
            changedSensor.containerType = value.target.value;
        } else if (object === 'reportingTime') {
            changedSensor.reportingTime = value.target.value;
        } else if (object === 'messageCenter') {
            changedSensor.messageCenter = value;
        }

        setSensor(changedSensor);
    }

    function checkIsValidNumber(numberValue: any): number | null | string {
        if (numberValue === '') {
            return '';
        }
        const numericValue = Number(numberValue);
        if (isNumber(numericValue) && !isNaN(numericValue)) {
            if (numberValue >= 0) {
                return numericValue;
            }
        }
        return null;
    }

    async function unlinkSensor() {
        if (user && sensor.users.indexOf(user.uid) >= 0) {
            sensor.users.splice(sensor.users.indexOf(user.uid), 1);

            try {
                await FirestoreSensorClass.updateSensor({ id: sensor.id, users: sensor.users } as Sensor);
                const log = {
                    createdAt: new Date(),
                    maintenance: `user: ${user.uid}, unclaimed sensor ${sensor.id}`,
                    reportedBy: user.uid,
                    station: sensor.id,
                    type: 'unclaim',
                };
                await insertSensorLog(sensor.id, log);
                enqueueSnackbar('Un-claimed the sensor-show', {variant: 'success'});
            } catch(error) {
                enqueueSnackbar('Failed to un-claim the sensor-show', {variant: 'error'});
            }
            setConfirmationDialogOpen(false);
            goToSensors();
        }
    }

    return (<>
        {isEmpty(sensor) && <>sensor was not found</>}
        {!isEmpty(sensor) && <>
            <h2>Sensor: {sensor.name}</h2>
            <UiBox header="General information">
                <Grid container>
                    <Grid item xs={6} padding={1}>
                        <UiTextField
                            label="Sensor id"
                            disabled
                            value={sensor.id || ''}
                        />
                    </Grid>
                    <Grid item xs={6} padding={1}>
                        <UiTextField
                            label="Sensor name"
                            onChange={(value) => changeData('label', value)}
                            value={sensor.label || ''}
                        />

                    </Grid>
                </Grid>
            </UiBox>
            <SensorShowStatus sensor={sensor} sensorStatus={sensorStatus} sensorStatuses={sensorStatuses} changeData={changeData} />
            <SensorMessagingCenter sensor={sensor} changeData={changeData} />
            <SensorShowSettings sensor={sensor} changeData={changeData} />
            <div style={{textAlign: 'right', marginRight: '30px'}}>
                <UiButton
                    name="save"
                    icon={<SaveCheck style={{height: '20px', verticalAlign: 'middle'}}/>}
                    onClick={saveSensor}
                />
            </div>
            <UiBox header={`Sensor status log ${sensorStatuses.length && `(${sensorStatuses.length})`}`} closeable isClosed>
                <SensorShowStatusLogList sensor={sensor} sensorStatuses={sensorStatuses} />
            </UiBox>
            <UiBox header={`Sensor log ${sensorLogs?.length && `(${sensorLogs.length})`}`} closeable isClosed>
                {sensorLogs?.map((log, index) => {
                    return <Grid container key={index}>
                        <Grid item xs={4}>{log.maintenance}</Grid>
                        <Grid item xs={4}>{JSON.stringify(log?.createdAt?.toDate())}</Grid>
                        <Grid item xs={4}>Type: {log.type}</Grid>
                    </Grid>
                })}
            </UiBox>
            <ApiSensor sensorId={sensorId}/>


            <div style={{ position: 'relative' }}>
                <div style={{ textAlign: 'right', paddingRight: '30px' }}>
                    <UiButton
                        onClick={() => setConfirmationDialogOpen(true)}
                        name="Un-claim the sensor"
                        icon={<Delete style={{height: '20px', verticalAlign: 'middle'}}/>}
                    />
                    <Dialog
                        open={confirmationDialogOpen}
                        onClose={() => setConfirmationDialogOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Are you sure you want to unclaim the sensor?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">By clicking on the unclaim button, your sensor will disappear
                                from the list of yours, although you will be able to claim it again anytime, as long as you still posess the
                                sensor id and also the pin that is required to claim the sensor.</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <UiButton
                                onClick={() => setConfirmationDialogOpen(false)}
                                name="Cancel"
                                style={{ marginBottom: '10px', marginRight: '10px' }}
                            />
                            <UiButton
                                onClick={unlinkSensor}
                                name="Un-claim the sensor"
                                icon={<Delete style={{height: '20px', fill: 'blue', verticalAlign: 'middle'}}/>}
                                style={{ marginBottom: '10px', marginRight: '10px' }}
                            />
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        </>}
    </>);
}